﻿@import "variables";


/*  --------------------------------------------------
    5.0 NAVIGATION             
    -------------------------------------------------- */
html.reveal-out {
    overflow: hidden;
}

html.reveal-out #site {
    height: 0px;
}

html.reveal-out .content {
    position: relative;
    z-index: -5;
}

.navigation {
    position: relative;
    z-index: 5;
    height: 0;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -ms-opacity: 0;
    opacity: 0;
    -webkit-transform: perspective(600px) rotateX(15deg);
    -moz-transform: perspective(600px) rotateX(15deg);
    -ms-transform: perspective(600px) rotateX(15deg);
    transform: perspective(600px) rotateX(15deg);
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    transition: none;
}

html.reveal-out .navigation {
    display: table;
    width: 100%;
    height: 100vh;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -ms-opacity: 1;
    opacity: 1;
    -webkit-transform: perspective(600px) rotateX(0deg);
    -moz-transform: perspective(600px) rotateX(0deg);
    -ms-transform: perspective(600px) rotateX(0deg);
    transform: perspective(600px) rotateX(0deg);
    -webkit-transition: transform 0.5s cubic-bezier(.10,.20,.10,.5) 0.1s, opacity 0.5s cubic-bezier(.10,.20,.10,.5) 0.1s;
    -moz-transition: transform 0.5s cubic-bezier(.10,.20,.10,.5) 0.1s, opacity 0.5s cubic-bezier(.10,.20,.10,.5) 0.1s;
    -ms-transition: transform 0.5s cubic-bezier(.10,.20,.10,.5) 0.1s, opacity 0.5s cubic-bezier(.10,.20,.10,.5) 0.1s;
    transition: transform 0.5s cubic-bezier(.10,.20,.10,.5) 0.1s, opacity 0.5s cubic-bezier(.10,.20,.10,.5) 0.1s;
}



html.reveal-out .navigation .main {
    display: block;
}

.navigation .reveal {
    visibility: hidden;
    display: none;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    overflow-x: hidden;
    padding: 80px 0 20px;

    @media (min-width:768px) {
        padding: 140px 0 20px;
    }
}

@media (min-width:992px) {
    .navigation .reveal > .login {
        display: none !important;
    }
}

@media (max-width:992px) {
    .navigation .reveal > .login {
        width: 100%;
        padding: 20px;
    }

    .navigation .reveal > .login .login__toggle {
        width: 100%;
    }
}

html.reveal-out .navigation .reveal {
    visibility: visible;
    overflow-x: hidden;
    display: table-cell;
}
/*  --------------------------------------------------
    5.1 MAIN NAV             
    -------------------------------------------------- */
.navigation nav.main {
    padding: 0;
    text-align: left;
    max-width: 500px;
    margin: 0 auto;
    //level 1
    > ul > li > span > a {
        text-transform: uppercase;
    }
    //level 2
    > ul > li > ul > li > span > a {
        text-transform: uppercase;
        font-weight: 400;
    }
    //level 3
    > ul > li > ul > li > ul > li > span > a {
        margin: 0;
        font-weight: 400;
        padding: 20px 0 20px 50px;

        @media (min-width:992px) {
            font-size: 18px;
        }
    }

    ul li span {
        position: relative;
        display: block;
    }

    ul li span span {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 60px;
        margin: 0;
        padding-top: 10px;
        text-align: center;
        color: $c5;
        height: 100%;

        &:hover, &:focus {
            cursor: pointer;

            &:before {
                color: $c7;
            }
        }

        @media (min-width:992px) {
            right: -50px;
            padding-top: 22px;
        }
    }

    ul li span span:focus {
        outline: 2px dotted white;
    }


    ul li span:before {
        z-index: 99;
    }
}

html.touch .navigation nav.main ul li span:before {
    z-index: 101;
}

.navigation nav.main ul li a {
    display: block;
}

.navigation nav.main ul li span a {
    position: relative;
    margin-right: 60px;
    color: $c5;
    padding: 15px 20px;


    @media (min-width:992px) {
        margin-right: 0;
        padding: 22px 15px;
    }
}

.navigation nav.main ul li span a {
    z-index: 101;
}

html.touch .navigation nav.main > ul > li > span a {
    z-index: 99;
}

.navigation nav.main > ul > li > span.active,
html.touch .navigation nav.main > ul > li.open,
html.no-touch .navigation nav.main > ul > li:hover, html.no-touch .navigation nav.main > ul > li:focus {
    @include color($c7,1);
}

.navigation nav.main > ul > li > span.active > a,
html.touch .navigation nav.main > ul > li.open > span > a,
html.no-touch .navigation nav.main > ul > li:hover > span > a, html.no-touch .navigation nav.main > ul > li:focus > span > a {
}

.navigation nav.main ul li span a:hover, .navigation nav.main ul li span a:focus {
    @include color($c7,1);
}


.navigation nav.main > ul > li.has-child ul {
    position: relative;
    @include color(#8c0001,1);
}

.navigation nav.main ul li span a::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 3px;
    height: 100%;
}

.navigation nav.main ul li.has-child ul {
    display: none;
}

.navigation nav.main ul li.has-child.open-mobile > ul {
    display: block;
}

.navigation nav.main > ul > li > span a {
    z-index: 99;
}

.navigation nav.main ul li span:before {
    z-index: 101;
}

.navigation nav.main ul li.has-child.open > span:before::before {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.navigation nav.main {
    font-size: 16px;
}

.navigation nav.main > ul > li > span {
    font-size: 20px;
}

.navigation nav.main > ul > li > span:not(.active) > a:hover, .navigation nav.main > ul > li > span:not(.active) > a:focus {
    @include color($c7,1);
}

@media (min-width:768px) {

    .navigation nav.main {
        font-size: 20px;
    }

    .navigation nav.main > ul > li > span {
        font-size: 35px;
    }

    .navigation nav.main > ul > li > span:not(.active) > a:hover, .navigation nav.main > ul > li > span:not(.active) > a:focus {
        @include color($c7,1);
    }
}

@media (min-width:992px) {

    .navigation nav.main {
        font-size: 25px;
    }

    .navigation nav.main > ul > li > span {
        font-size: 35px;
    }

    .navigation nav.main > ul > li > span:not(.active) > a:hover, .navigation nav.main > ul > li > span:not(.active) > a:focus {
        @include color($c7,1);
    }
}

@media (min-width:1200px) {

    .navigation nav.main {
        font-size: 25px;
    }

    .navigation nav.main > ul > li > span {
        font-size: 45px;
    }

    .navigation nav.main > ul > li > span > a:hover, .navigation nav.main > ul > li > span > a:focus {
        font-size: 50px;
    }
}

.navigation nav.main {
    display: none;
}
/*  --------------------------------------------------
    5.2 TOP NAVIGATION             
    -------------------------------------------------- */
.navigation nav.top {
    padding: 0;
}

.navigation nav.main + nav.top {
    padding-top: 30px;
}

.navigation nav.top > ul,
.navigation nav.top ul .dropdown-menu {
    text-align: center;
}

.navigation nav.top ul > li {
    display: block;
    padding: 15px 0;
}

.navigation nav.top li a {
    color: $c5;
    @include color($c5,1);
}

.navigation nav.top ul .dropdown-menu {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    float: none !important;
    width: auto;
    background: none;
    border: 0 none;
    box-shadow: none;
}

.navigation nav.top ul .dropdown-menu li a {
    padding: 0;
    background: none !important;
}

@media (min-width:768px) {
}

@media (min-width:992px) {
}

@media (min-width:1200px) {
}

nav.top a:hover, nav.top a:focus {
    text-decoration: underline !important;
}

nav.top li:hover, nav.top li:focus {
    @include color($c3,1);
    -webkit-transition: all 0.3s ease-in-out 0s, height 0.3s ease-in-out 0s, top 0.3s ease-in-out 0s, bottom 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s, height 0.3s ease-in-out 0s, top 0.3s ease-in-out 0s, bottom 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s, height 0.3s ease-in-out 0s, top 0.3s ease-in-out 0s, bottom 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s, height 0.3s ease-in-out 0s, top 0.3s ease-in-out 0s, bottom 0.3s ease-in-out 0s;
    font-size: 19px;
}
/*  --------------------------------------------------
    5.3 SUB NAVIGATION             
    -------------------------------------------------- */
nav.sub {
    position: relative;
    overflow: hidden;
    height: 100%;
    margin-bottom: 30px;
}

nav.sub p.heading {
    font-size: 18px;
    margin-top: 0;
}

nav.sub.categories p.heading,
nav.sub h3 a {
    display: block;
    color: $c3;
    @include color($c3,1);
}

nav.sub .inner {
    padding-right: 0;
    padding-left: 0;
}

nav.sub ul ul {
    font-size: 16px;
}

nav.sub ul li {
    position: relative;
}

nav.sub ul li a {
    position: relative;
    display: block;
    padding: 10px 15px;
    color: $c3;
    @include color($c3,1);
    background: transparent;
    -webkit-transition: all 0.2s ease-in-out 0s;
    -moz-transition: all 0.2s ease-in-out 0s;
    -ms-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
}

nav.sub ul ul {
    font-size: 90%;
}

nav.sub ul ul li a {
    padding: 10px 25px;
}

nav.sub ul li:not(.active) a:hover, nav.sub ul li:not(.active) a:focus {
    @include color($c3,1);
}

nav.sub ul li.active > a {
    color: #fff;
    @include background-color($c1,1);
    font-weight: 700;
}

nav.sub ul li a::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
}

nav.sub ul li a:hover::before, nav.sub ul li a:focus::before {
    @include color($c7,1);
}

nav.sub ul > li.active > a::before {
}

@media (min-width:768px) {
}

@media (min-width:992px) {

    nav.sub h3 {
        font-size: 20px;
    }

    nav.sub ul li a {
        padding: 15px 20px;
    }

    nav.sub ul ul li a {
        padding-left: 30px;
    }
}

@media (min-width:1200px) {

    nav.sub h3 {
        font-size: 22px;
    }

    nav.sub ul li a {
        padding: 15px 30px;
    }

    nav.sub ul ul li a {
        padding-left: 40px;
    }
}
