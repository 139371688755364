﻿@import "variables";


/*  --------------------------------------------------
    10.2 BANNER  
    -------------------------------------------------- */

/* will not work on iOS: https://stackoverflow.com/questions/23236158/how-to-replicate-background-attachment-fixed-on-ios */
body:not(.browser-ios) .apc.banner .item.parallax .image {
    background-attachment: fixed;
}


body.browser-ios .apc.banner .item.takeover,
body.browser-ios .apc.banner .item.takeover .container,
body.browser-ios .apc.banner .item.takeover .row {
    height: 100vh;
    height: calc(100vh - 68px);
    height: -o-calc(100vh - 68px);
    height: -webkit-calc(100vh - 68px);
    height: -moz-calc(100vh - 68px);
}

header + #content > .apc.banner:first-child {
    margin-top: -70px;
}

header + #content > .apc.banner:first-child .item:not(.takeover) .info {
    padding-top: 50px;
}


.apc.banner {
    overflow: hidden;
    height: auto;
    position: relative;
    z-index: 0;


    .background-color {
        padding: 25px;
    }

    .c1-bg {
        .background-color {
            @include background-color($c1,1);
        }
    }

    .c2-bg {
        .background-color {
            @include background-color($c2,1);
        }
    }

    .c3-bg {
        .background-color {
            @include background-color($c3,1);
        }
    }

    .c4-bg {
        .background-color {
            @include background-color($c4,1);
        }
    }

    .c5-bg {
        .background-color {
            @include background-color($c5,1);
        }
    }

    .c6-bg {
        .background-color {
            @include background-color($c6,1);
        }
    }



    .item {
        position: relative;
        z-index: 0;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;


        &.overlay::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 10;
            background-color: rgba(0,0,0,.4) !important;
        }


        &.short,
        &.short .container,
        &.short .row {
            height: 45vh;
            min-height: 320px;
        }

        &.medium,
        &.medium .container,
        &.medium .row {
            height: 65vh;
            min-height: 400px;
        }

        &.takeover,
        &.takeover .container,
        &.takeover .row {
            height: 100vh;
        }
    }

    &.overlay::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
    }

    .image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }


    .info {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 11;
        width: 100%;
        height: 100%;
        padding: 0;
        text-align: left;

        .heading.main {
            margin: 0;
            text-transform: uppercase;
            letter-spacing: .1em;
            font-weight: 800;
            text-align: left;

            @media (min-width:992px) {
                font-size: 38px;
            }
        }

        .heading.main + .text {
            margin-top: 10px;
            text-align: left;
            font-size: 22px;
        }

        .text :first-child {
            margin-top: 0;
        }

        .text :last-child {
            margin-bottom: 0;
        }

        .heading.main + .link {
            margin: 25px 0 0;
        }

        .text + .link {
            margin: 40px 0 0;
            text-align: center;

            a:not(.btn) {
                @include background-color($c1,1);
                color: #fff;
                border-color: #fff;

                &:hover, &:focus {
                    @include background-color(#fff,1);
                    color: $c1;
                    border-color: $c1;
                }
            }
        }
    }
    /* SCROLL PROMPT */
    .scroll-prompt {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 100;
        width: 100%;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-delay: 3s;
        animation-delay: 3s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
    }

    .scroll-prompt a {
        display: inline-block;
        width: 100%;
        height: 40px;
        margin: 0 auto;
        cursor: pointer;
        text-decoration: none !important;
    }

    .scroll-prompt a i {
        font-size: 30px;
        line-height: 40px;
        display: block;
        padding: 0;
        margin: 0 0 0 2px;
    }

    .scroll-prompt .mouse-scroll {
        position: relative;
        width: 21px;
        height: 35px;
        margin: 0 auto;
        border-width: 2px;
        border-style: solid;
        border-color: $c5;
        @include border-color($c5,1);
        border-radius: 10px;
    }

    .scroll-prompt .mouse-scroll::before {
        content: "";
        position: absolute;
        top: 10px;
        left: 7px;
        width: 3px;
        height: 6px;
        border-radius: 50%;
        background-color: $c5;
        @include background-color($c5,1);
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
        -webkit-animation-duration: 1.25s;
        animation-duration: 1.25s;
        -webkit-animation-delay: 3s;
        animation-delay: 3s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-name: fadeInDown;
        animation-name: fadeInDown;
    }
}

@media (min-width:768px) {

    .apc.banner .item.short,
    .apc.banner .item.short .container,
    .apc.banner .item.short .row {
        min-height: 380px;
    }

    .apc.banner .item.medium,
    .apc.banner .item.medium .container,
    .apc.banner .item.medium .row {
        min-height: 480px;
    }

    .apc.banner .item.takeover,
    .apc.banner .item.takeover .container,
    .apc.banner .item.takeover .row {
        height: 100vh;
    }

    body.browser-ios .apc.banner .item.takeover,
    body.browser-ios .apc.banner .item.takeover .container,
    body.browser-ios .apc.banner .item.takeover .row {
        height: 100vh;
        height: calc(100vh - 26px);
        height: -o-calc(100vh - 26px);
        height: -webkit-calc(100vh - 26px);
        height: -moz-calc(100vh - 26px);
    }
}

@media (min-width:992px) {
}

@media (min-width:768px) and (max-width:1199px) {

    .apc.banner .info .table {
        padding-right: 40px;
        padding-left: 40px;
    }
}

@media (min-width:1200px) {

    .apc.banner .item.short,
    .apc.banner .item.short .container,
    .apc.banner .item.short .row {
        min-height: 530px;
    }

    .apc.banner .item.medium,
    .apc.banner .item.medium .container,
    .apc.banner .item.medium .row {
        min-height: 700px;
    }

    .apc.banner .scroll-prompt a {
        height: auto;
        padding-bottom: 10px;
    }

    .apc.banner .scroll-prompt a span {
        font-size: 9px;
        text-transform: uppercase;
        letter-spacing: 0.09em;
        display: block;
        margin-top: 15px;
        display: none;
    }

    .apc.banner .scroll-prompt a i {
        font-size: 20px;
    }
}

@media (min-width:1600px) {

    body.fixed-width .apc.banner .item {
        background-size: 1600px auto;
    }
}

.banner, .banner .c3-text {
    color: $c5;
    @include color($c5,1);
}

.banner .c3-borders {
    border-color: $c3;
}
/*  --------------------------------------------------
    10.2.2 BANNER VIDEOS
    -------------------------------------------------- */
.apc.banner .item .video {
}

.apc.banner .item .video .video-player {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1; /* for accessing the video by click */
    overflow: hidden;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}


@media (min-width:0) and (max-width:767px) {
}

@media (min-width:768px) {
}

@media (min-width:992px) {
}

@media (min-width:1200px) {
}

a.video-play, a.video-pause {
    color: white !important;
    font-size: 2.2rem;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: transparent;
    border-color: transparent;
    z-index: 100;
}

a.video-play:focus, a.video-pause:focus {
    outline: 2px white;
}

/*  --------------------------------------------------
    10.2.3 BANNER Play/Pause Buttons
    -------------------------------------------------- */
.banner-carousel {

    &-controls {
        position: relative;

        button {
            background-color: transparent;
            border: none;
            color: rgba(255, 255, 255, 0.75);
            display: block;
            font-size: 20px;
            line-height: 1;
            outline: none;
            padding: 0;
            width: 20px;
            height: 32px;

            &:focus,
            &:hover {
                transform: scale(1.3);
            }

            &.play,
            &.pause {
                font-size: 13px;

                &:focus {
                    border: #000000 dotted 1px;
                }
            }

            &.prev,
            &.next {
                > .fa {
                    vertical-align: top;
                }

                &:focus {
                    border: #000000 dotted 1px;
                }
            }
        }

        ul {
            font-size: 0;
            margin: 0;
            position: absolute;
            right: 0;
            top: -40px;
            background-color: #4c6165;

           

            &.list-inline {
                > li {
                    display: inline-block;
                }
            }

            li {
                margin: 0;
                padding: 0;
                vertical-align: top;
            }
        }
    }
}