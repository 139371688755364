﻿@import "variables";

/*  ----------------------------------------------------------------------------------------------------
    6.0 PROMO PODS         
    ---------------------------------------------------------------------------------------------------- */
.promo-pods {
    position: relative;
    height: 100%;
}

.promo-pods .row:not(.alt):not(.overview):not(.outro) {
    margin-top: -30px;
}

.promo-pods .row.alt {
    margin: 0;
}

.promo-pods .item {
    position: relative;
    margin: 30px 0 0;
    border: 20px solid #e1e1e1;
    padding: 0;
    background-color: #fff;
}

@media-desktop {
    .promo-pods .item:last-child {
        border: none;
    }
}

.promo-pods .alt .item {
    padding: 0;
    margin: 0;
    overflow: hidden;
}

html.no-touch .promo-pods .item a:hover .heading,
html.no-touch .promo-pods .item a:hover .text, html.no-touch .promo-pods .item a:focus .heading,
html.no-touch .promo-pods .item a:focus .text {
    text-decoration: none;
}

.promo-pods .item .inner .image {
    margin: -15px -15px 15px;
}

.promo-pods .alt .item .image {
    margin: 0;
}

.promo-pods .item img {
    overflow: hidden;
}

.promo-pods .alt .item .info {
    position: absolute; /* right:0; bottom:0; left:0; */
    right: 0;
    bottom: 0;
    left: 0;
    padding: 15px;
    background-color: rgba(17,118,187,0.8);
}

html.no-touch .promo-pods .alt .item .info {
    top: 0;
}

html.no-touch .promo-pods .alt .item a .info {
    background-color: rgba(17,118,187,0.3);
    opacity: 0;
    -webkit-transform: scale(2);
    -moz-transform: scale(2);
    -ms-transform: scale(2);
    -o-transform: scale(2);
    transform: scale(1.25);
}

html.no-touch .promo-pods .alt .item a:hover .info, html.no-touch .promo-pods .alt .item a:focus .info {
    background-color: rgba(17,118,187,0.8);
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
}

.promo-pods .item a {
}

.promo-pods .item .heading {
    display: block;
    margin: 0;
    font-size: 18px;
    text-decoration: none;
}

.promo-pods .item .text {
    display: block;
    margin-top: 10px;
    padding-bottom: 60px;
}

.promo-pods .item .heading + .text {
    margin-top: 10px;
}

.promo-pods .item .text :first-child {
    margin-top: 0;
}

.promo-pods .item .text :last-child {
    margin-bottom: 0;
}

.promo-pods .item .text a {
    display: inline;
}

html.no-touch .promo-pods .item .text a:hover, html.no-touch .promo-pods .item .text a:focus {
    text-decoration: underline;
}

.promo-pods .item .link {
    margin: 20px 0 0;
}

.promo-pods .item .link a {
}


@media (min-width:620px) and (max-width:767px) {

    .promo-pods .item {
        padding-left: 25px;
        padding-right: 25px;
    }

    .promo-pods .c5-text {
        padding-left: 25px;
        padding-right: 25px;
    }

    .promo-pods .item:nth-child(2n+1) {
        clear: both;
    }
}

@media (min-width:768px) and (max-width:991px) {

    .promo-pods .item:nth-of-type(2n+1) {
        clear: both;
    }
}

@media (min-width:992px) {

    .promo-pods .item:nth-of-type(3n+1) {
        clear: both;
    }

    .promo-pods .item .inner .image {
        margin: -20px -20px 20px;
    }

    .promo-pods .item .heading {
        font-size: 22px;
    }
}

@media (min-width:1200px) {

    .promo-pods .item .inner .image {
        margin: -30px -30px 30px;
    }

    .promo-pods .item .heading {
        font-size: 25px;
    }
}

.promo-pods .row::before {
    content: normal;
}

.promo-pods h2 {
    font-size: 17px;
}

.promo-pods .item .link {
    position: absolute;
    left: 30px;
    margin: 0;
    bottom: 30px;
}

@media (min-width: 620px) {
    .promo-pods .item .link {
        left: 40px;
    }
}

@media (min-width: 768px) and (max-width:991px) {
    .promo-pods .item .link {
        left: 30px;
    }
}

.promo-pods .row-eq-height {
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 767px) {
    .promo-pods .row-eq-height {
        display: block;
    }
}
