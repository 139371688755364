﻿$c1: #b40000;
$c2: #646464;
$c3: #000000;
$c4: #f7f7f7;
$c5: #ffffff;
$c6: #e1e1e1;
$c7: #ABABAB;

$primary: $c1;
$secondary: $c2;
$tertiary: $c3;
$quarternary: $c4;
$light: $c5;
$dark: $c6;

@mixin background-color($color, $opacity) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}

@mixin color($color, $opacity) {
    color: $color; /* The Fallback */
    color: rgba($color, $opacity);
}

@mixin border-color($color, $opacity) {
    border-color: $color; /* The Fallback */
    border-color: rgba($color, $opacity);
}

@mixin border-top-color($color, $opacity) {
    border-color: $color; /* The Fallback */
    border-color: rgba($color, $opacity);
}

@mixin border-right-color($color, $opacity) {
    border-color: $color; /* The Fallback */
    border-color: rgba($color, $opacity);
}

@mixin border-bottom-color($color, $opacity) {
    border-color: $color; /* The Fallback */
    border-color: rgba($color, $opacity);
}

@mixin border-left-color($color, $opacity) {
    border-color: $color; /* The Fallback */
    border-color: rgba($color, $opacity);
}