@import "variables";

/* 	--------------------------------------------------
    uSkinned.net

	PALETTE
    -------------------------------------------------- */


/* 	--------------------------------------------------
	TABLE OF CONTENTS
    -------------------------------------------------- 

	1.0 BACKGROUNDS
	2.0 TEXT
	3.0 BORDERS
	4.0 BUTTONS
	
	
	COLOURS 
	
	c1	#c6131a		198,19,26
	c2	#646464		100,100,100
	c3	#000000		0,0,0
	c4	#f7f7f7		247,247,247
	c5 	#ffffff		255,255,255
	c6  	#e1e1e1		225,225,225
	
	
*/


/*	--------------------------------------------------
	1.0 BACKGROUNDS
	-------------------------------------------------- */

.c1-bg,
body,
#top-link-block a {
    @include background-color($c1,1);
    color: $c5;
}

    .c1-bg a {
        color: #fff;
        font-weight: 600;
    }

        .c1-bg a:hover, .c1-bg a:focus {
            color: $c3;
            text-decoration: underline;
        }

        .c1-bg.bg-perc_90 {
            @include background-color($c1,0.9);
        }

    .c1-bg.bg-perc_80,
    apc.pod-windows .item a.info.c1-bg:hover::before, .apc.pod-windows .item a.info.c1-bg:focus::before {
        @include background-color($c1,0.8);
    }

    .c1-bg.bg-perc_70 {
        @include background-color($c1,0.7);
    }

    .c1-bg.bg-perc_60 {
        @include background-color($c1,0.6);
    }

    .c1-bg.bg-perc_50,
    .apc.banner .item.overlay.c1-bg::before,
    .apc.pod-windows .item.overlay .info.c1-bg::before {
        @include background-color($c1,0.5);
    }

    .c1-bg.bg-perc_40 {
        @include background-color($c1,0.4);
    }

    .c1-bg.bg-perc_30 {
        @include background-color($c1,0.3);
    }

    .c1-bg.bg-perc_20 {
        @include background-color($c1,0.2);
    }

    .c1-bg.bg-perc_10 {
        @include background-color($c1,0.1);
    }

.btn.no-bg.c1-borders.c1-text {
    @include background-color($c1,1);
    color: #fff;
    border-color: #fff;
}

    .btn.no-bg.c1-borders.c1-text:hover, .btn.no-bg.c1-borders.c1-text:focus {
        color: #c6131a;
        background-color: #fff;
        border-color: #c6131a;
    } 


.c2-bg {
    background-color: #fff;
    @include background-color($c2,1);
}

    .c2-bg.bg-perc_90 {
        @include background-color($c2,0.9);
    }

    .c2-bg.bg-perc_80,
    .apc.pod-windows .item a.info.c2-bg:hover::before, .apc.pod-windows .item a.info.c2-bg:focus::before {
        @include background-color($c2,0.8);
    }

    .c2-bg.bg-perc_70 {
        @include background-color($c2,0.7);
    }

    .c2-bg.bg-perc_60 {
        @include background-color($c2,0.6);
    }

    .c2-bg.bg-perc_50,
    .apc.banner .item.overlay.c2-bg::before,
    .apc.pod-windows .item.overlay .info.c2-bg::before {
        @include background-color($c2,0.5);
    }

    .c2-bg.bg-perc_40 {
        @include background-color($c2,0.4);
    }

    .c2-bg.bg-perc_30 {
        @include background-color($c2,0.3);
    }

    .c2-bg.bg-perc_20 {
        @include background-color($c2,0.2);
    }

    .c2-bg.bg-perc_10 {
        @include background-color($c2,0.1);
    }

.c3-bg,
.modal-backdrop, .modal-backdrop.in,
footer {
    background-color:$c3;
    @include background-color($c3,1);
}

    .c3-bg.bg-perc_90 {
        @include background-color($c3,0.9);
    }

    .c3-bg.bg-perc_80,
    .apc.pod-windows .item a.info.c3-bg:hover::before, .apc.pod-windows .item a.info.c3-bg:focus::before {
        @include background-color($c3,0.8);
    }

    .c3-bg.bg-perc_70 {
        @include background-color($c3,0.7);
    }

    .c3-bg.bg-perc_60 {
        @include background-color($c3,0.6);
    }

    .c3-bg.bg-perc_50,
    .apc.banner .item.overlay.c3-bg::before,
    .apc.pod-windows .item.overlay .info.c3-bg::before {
        @include background-color($c3,0.5);
    }

    .c3-bg.bg-perc_40 {
        @include background-color($c3,0.4);
    }

    .c3-bg.bg-perc_30 {
        @include background-color($c3,0.3);
    }

    .c3-bg.bg-perc_20 {
        @include background-color($c3,0.2);
    }

    .c3-bg.bg-perc_10 {
        @include background-color($c3,0.1);
    }

.c4-bg {
    background-color:$c4;
    @include background-color($c4,1);
}

    .c4-bg.bg-perc_90 {
        @include background-color($c4,0.9);
    }

    .c4-bg.bg-perc_80,
    .apc.pod-windows .item a.info.c4-bg:hover::before, .apc.pod-windows .item a.info.c4-bg:focus::before {
        @include background-color($c4,0.8);
    }

    .c4-bg.bg-perc_70 {
        @include background-color($c4,0.7);
    }

    .c4-bg.bg-perc_60 {
        @include background-color($c4,0.6);
    }

    .c4-bg.bg-perc_50,
    .apc.banner .item.overlay.c4-bg::before,
    .apc.pod-windows .item.overlay .info.c4-bg::before {
        @include background-color($c4,0.5);
    }

    .c4-bg.bg-perc_40 {
        @include background-color($c4,0.4);
    }

    .c4-bg.bg-perc_30 {
        @include background-color($c4,0.3);
    }

    .c4-bg.bg-perc_20 {
        @include background-color($c4,0.2);
    }

    .c4-bg.bg-perc_10 {
        @include background-color($c4,0.1);
    }

.c5-bg, #site, .cookie-notice, .spc.pricing-menu {
    background-color: $c5;
    @include background-color($c5,1);
}

    .c5-bg.bg-perc_90 {
        @include background-color($c5,0.9);
    }

    .c5-bg.bg-perc_80,
    .apc.pod-windows .item a.info.c5-bg:hover::before, .apc.pod-windows .item a.info.c5-bg:focus::before {
        @include background-color($c5,0.8);
    }

    .c5-bg.bg-perc_70 {
        @include background-color($c5,0.7);
    }

    .c5-bg.bg-perc_60 {
        @include background-color($c5,0.6);
    }

    .c5-bg.bg-perc_50,
    .apc.banner .item.overlay.c5-bg::before,
    .apc.pod-windows .item.overlay .info.c5-bg::before {
        background-color: rgba(000,000,000,0.5);
    }

    .c5-bg.bg-perc_40 {
        @include background-color($c5,0.4);
    }

    .c5-bg.bg-perc_30 {
        @include background-color($c5,0.3);
    }

    .c5-bg.bg-perc_20 {
        @include background-color($c5,0.2);
    }

    .c5-bg.bg-perc_10 {
        @include background-color($c5,0.1);
    }

.c6-bg {
    background-color: $c6;
    @include background-color($c6,1);
}

    .c6-bg.bg-perc_90 {
        @include background-color($c6,0.9);
    }

    .c6-bg.bg-perc_80,
    .apc.pod-windows .item a.info.c6-bg:hover::before, .apc.pod-windows .item a.info.c6-bg:focus::before {
        @include background-color($c6,0.8);
    }

    .c6-bg.bg-perc_70 {
        @include background-color($c6,0.7);
    }

    .c6-bg.bg-perc_60 {
        @include background-color($c6,0.6);
    }

    .c6-bg.bg-perc_50,
    .apc.banner .item.overlay.c6-bg::before,
    .apc.pod-windows .item.overlay .info.c6-bg::before {
        @include background-color($c6,0.5);
    }

    .c6-bg.bg-perc_40 {
        @include background-color($c6,0.4);
    }

    .c6-bg.bg-perc_30 {
        @include background-color($c6,0.3);
    }

    .c6-bg.bg-perc_20 {
        @include background-color($c6,0.2);
    }

    .c6-bg.bg-perc_10 {
        @include background-color($c6,0.1);
    }



/*	--------------------------------------------------
	2.0 TEXT
	-------------------------------------------------- */

body {
    color: #555555;
    background-color: $c5;
}

a, a:hover, a:focus, a:active {
    color: $c1;
    @include color($c1,1);
}

a.hover-check:hover, a.hover-check:focus, a.hover-check:active {
    color: $c1;
    @include color($c5,1);
}

.heading, h1, h2, h3, h4, h5, h6, .tables .head {
    color: $c3;
}

.c1-bg .text .heading, .c1-bg .text h1, .c1-bg .text h2, .c1-bg .text h3, .c1-bg .text h4, .c1-bg .text h5, .c1-bg .text h6,
.c3-bg .text .heading, .c3-bg .text h1, .c3-bg .text h2, .c3-bg .text h3, .c3-bg .text h4, .c3-bg .text h5, .c3-bg .text h6 {
    color: inherit;
}

.c1-text,
.c1-text:hover, .c1-text:focus, .c1-text:active,
blockquote::before, blockquote::after {
    color: #c6131a;
    @include background-color($c1,1);
}

    .c1-text.text-perc_90 {
        @include background-color($c1,0.9);
    }

    .c1-text.text-perc_80 {
        @include background-color($c1,0.8);
    }

    .c1-text.text-perc_70 {
        @include background-color($c1,0.7);
    }

    .c1-text.text-perc_60 {
        @include background-color($c1,0.6);
    }

    .c1-text.text-perc_50 {
        @include background-color($c1,0.5);
    }

    .c1-text.text-perc_40 {
        @include background-color($c1,0.4);
    }

    .c1-text.text-perc_30 {
        @include background-color($c1,0.3);
    }

    .c1-text.text-perc_20 {
        @include background-color($c1,0.2);
    }

    .c1-text.text-perc_10 {
        @include background-color($c1,0.1);
    }

.c2-text,
.c2-text:hover, .c2-text:focus, .c2-text:active {
    color: $c2;
    @include color($c2,1);
}

    .c2-text.text-perc_90 {
        @include color($c2,0.9);
    }

    .c2-text.text-perc_80 {
        @include color($c2,0.8);
    }

    .c2-text.text-perc_70 {
        @include color($c2,0.7);
    }

    .c2-text.text-perc_60 {
        @include color($c2,0.6);
    }

    .c2-text.text-perc_50 {
        @include color($c2,0.5);
    }

    .c2-text.text-perc_40 {
        @include color($c2,0.4);
    }

    .c2-text.text-perc_30 {
        @include color($c2,0.3);
    }

    .c2-text.text-perc_20 {
        @include color($c2,0.2);
    }

    .c2-text.text-perc_10 {
        @include color($c2,0.1);
    }

.c3-text,
.c3-text:hover, .c3-text:focus, .c3-text:active {
    color: $c3;
    @include color($c3,1);
}

    .c3-text.text-perc_90 {
        @include color($c3,0.9);
    }

    .c3-text.text-perc_80 {
        @include color($c3,0.8);
    }

    .c3-text.text-perc_70 {
        @include color($c3,0.7);
    }

    .c3-text.text-perc_60 {
        @include color($c3,0.6);
    }

    .c3-text.text-perc_50 {
        @include color($c3,0.5);
    }

    .c3-text.text-perc_40 {
        @include color($c3,0.4);
    }

    .c3-text.text-perc_30 {
        @include color($c3,0.3);
    }

    .c3-text.text-perc_20 {
        @include color($c3,0.2);
    }

    .c3-text.text-perc_10 {
        @include color($c3,0.1);
    }

.c4-text,
.c4-text:hover, .c4-text:focus, .c4-text:active {
    color: $c4;
    @include color($c4,1);
}

    .c4-text.text-perc_90 {
        @include color($c4,0.9);
    }

    .c4-text.text-perc_80 {
        @include color($c4,0.8);
    }

    .c4-text.text-perc_70 {
        @include color($c4,0.7);
    }

    .c4-text.text-perc_60 {
        @include color($c4,0.6);
    }

    .c4-text.text-perc_50 {
        @include color($c4,0.5);
    }

    .c4-text.text-perc_40 {
        @include color($c4,0.4);
    }

    .c4-text.text-perc_30 {
        @include color($c4,0.3);
    }

    .c4-text.text-perc_20 {
        @include color($c4,0.2);
    }

    .c4-text.text-perc_10 {
        @include color($c4,0.1);
    }

.c5-text,
.c5-text:hover, .c5-text:focus, .c5-text:active {
    color: $c5;
    @include color($c5,1);
}

    .c5-text.text-perc_90 {
        @include color($c5,0.9);
    }

    .c5-text.text-perc_80 {
        @include color($c5,0.8);
    }

    .c5-text.text-perc_70 {
        @include color($c5,0.7);
    }

    .c5-text.text-perc_60 {
        @include color($c5,0.6);
    }

    .c5-text.text-perc_50 {
        @include color($c5,0.5);
    }

    .c5-text.text-perc_40 {
        @include color($c5,0.4);
    }

    .c5-text.text-perc_30 {
        @include color($c5,0.3);
    }

    .c5-text.text-perc_20 {
        @include color($c5,0.2);
    }

    .c5-text.text-perc_10 {
        @include color($c5,0.1);
    }

.c6-text,
.c6-text:hover, .c6-text:focus, .c6-text:active,
footer {
    color: $c6;
    @include color($c6,1);
}

    .c6-text.text-perc_90 {
        @include color($c6,0.9);
    }

    .c6-text.text-perc_80 {
        @include color($c6,0.8);
    }

    .c6-text.text-perc_70 {
        @include color($c6,0.7);
    }

    .c6-text.text-perc_60 {
        @include color($c6,0.6);
    }

    .c6-text.text-perc_50 {
        @include color($c6,0.5);
    }

    .c6-text.text-perc_40 {
        @include color($c6,0.4);
    }

    .c6-text.text-perc_30 {
        @include color($c6,0.3);
    }

    .c6-text.text-perc_20 {
        @include color($c6,0.2);
    }

    .c6-text.text-perc_10 {
        @include color($c6,0.1);
    }



/*	--------------------------------------------------
	3.0 BORDERS
	-------------------------------------------------- */

.c1-borders {
    border-color: #c6131a;
    @include border-color($c1,1);
}

    .c1-borders.borders-perc_90 {
        @include border-color($c1,0.9);
    }

    .c1-borders.borders-perc_80 {
        @include border-color($c1,0.8);
    }

    .c1-borders.borders-perc_70 {
        @include border-color($c1,0.7);
    }

    .c1-borders.borders-perc_60 {
        @include border-color($c1,0.6);
    }

    .c1-borders.borders-perc_50 {
        @include border-color($c1,0.5);
    }

    .c1-borders.borders-perc_40 {
        @include border-color($c1,0.4);
    }

    .c1-borders.borders-perc_30 {
        @include border-color($c1,0.3);
    }

    .c1-borders.borders-perc_20 {
        @include border-color($c1,0.2);
    }

    .c1-borders.borders-perc_10 {
        @include border-color($c1,0.1);
    }

.c2-borders {
    border-color: $c2;
    @include border-color($c2,1);
}

    .c2-borders.borders-perc_90 {
        @include border-color($c2,0.9);
    }

    .c2-borders.borders-perc_80 {
        @include border-color($c2,0.8);
    }

    .c2-borders.borders-perc_70 {
        @include border-color($c2,0.7);
    }

    .c2-borders.borders-perc_60 {
        @include border-color($c2,0.6);
    }

    .c2-borders.borders-perc_50 {
        @include border-color($c2,0.5);
    }

    .c2-borders.borders-perc_40 {
        @include border-color($c2,0.4);
    }

    .c2-borders.borders-perc_30 {
        @include border-color($c2,0.3);
    }

    .c2-borders.borders-perc_20 {
        @include border-color($c2,0.2);
    }

    .c2-borders.borders-perc_10 {
        @include border-color($c2,0.1);
    }

.c3-borders {
    border-color: $c3;
    @include border-color($c3,1);
}

    .c3-borders.borders-perc_90 {
        @include border-color($c3,0.9);
    }

    .c3-borders.borders-perc_80 {
        @include border-color($c3,0.8);
    }

    .c3-borders.borders-perc_70 {
        @include border-color($c3,0.7);
    }

    .c3-borders.borders-perc_60 {
        @include border-color($c3,0.6);
    }

    .c3-borders.borders-perc_50 {
        @include border-color($c3,0.5);
    }

    .c3-borders.borders-perc_40 {
        @include border-color($c3,0.4);
    }

    .c3-borders.borders-perc_30 {
        @include border-color($c3,0.3);
    }

    .c3-borders.borders-perc_20 {
        @include border-color($c3,0.2);
    }

    .c3-borders.borders-perc_10 {
        @include border-color($c3,0.1);
    }

.c4-borders {
    border-color: $c4;
    @include border-color($c4,1);
}

    .c4-borders.borders-perc_90 {
        @include border-color($c4,0.9);
    }

    .c4-borders.borders-perc_80 {
        @include border-color($c4,0.8);
    }

    .c4-borders.borders-perc_70 {
        @include border-color($c4,0.7);
    }

    .c4-borders.borders-perc_60 {
        @include border-color($c4,0.6);
    }

    .c4-borders.borders-perc_50 {
        @include border-color($c4,0.5);
    }

    .c4-borders.borders-perc_40 {
        @include border-color($c4,0.4);
    }

    .c4-borders.borders-perc_30 {
        @include border-color($c4,0.3);
    }

    .c4-borders.borders-perc_20 {
        @include border-color($c4,0.2);
    }

    .c4-borders.borders-perc_10 {
        @include border-color($c4,0.1);
    }

.c5-borders {
    border-color: $c5;
    @include border-color($c5,1);
}

    .c5-borders.borders-perc_90 {
        @include border-color($c5,0.9);
    }

    .c5-borders.borders-perc_80 {
        @include border-color($c5,0.8);
    }

    .c5-borders.borders-perc_70 {
        @include border-color($c5,0.7);
    }

    .c5-borders.borders-perc_60 {
        @include border-color($c5,0.6);
    }

    .c5-borders.borders-perc_50 {
        @include border-color($c5,0.5);
    }

    .c5-borders.borders-perc_40 {
        @include border-color($c5,0.4);
    }

    .c5-borders.borders-perc_30 {
        @include border-color($c5,0.3);
    }

    .c5-borders.borders-perc_20 {
        @include border-color($c5,0.2);
    }

    .c5-borders.borders-perc_10 {
        @include border-color($c5,0.1);
    }

.c6-borders {
    border-color: $c6;
    @include border-color($c6,1);
}

    .c6-borders.borders-perc_90 {
        @include border-color($c6,0.9);
    }

    .c6-borders.borders-perc_80 {
        @include border-color($c6,0.8);
    }

    .c6-borders.borders-perc_70 {
        @include border-color($c6,0.7);
    }

    .c6-borders.borders-perc_60 {
        @include border-color($c6,0.6);
    }

    .c6-borders.borders-perc_50 {
        @include border-color($c6,0.5);
    }

    .c6-borders.borders-perc_40 {
        @include border-color($c6,0.4);
    }

    .c6-borders.borders-perc_30 {
        @include border-color($c6,0.3);
    }

    .c6-borders.borders-perc_20 {
        @include border-color($c6,0.2);
    }

    .c6-borders.borders-perc_10 {
        @include border-color($c6,0.1);
    }



/*	--------------------------------------------------
	4.0 BUTTONS
	-------------------------------------------------- */

.btn.no-bg.c1-borders:hover, .btn.no-bg.c1-borders:active, .btn.no-bg.c1-borders:focus {
    @include color($c5,1);
    
}

.btn.no-bg.c2-borders:hover, .btn.no-bg.c2-borders:active, .btn.no-bg.c2-borders:focus {
    @include color($c5,1);
    @include background-color($c2,1);
}

.btn.no-bg.c3-borders:hover, .btn.no-bg.c3-borders:active, .btn.no-bg.c3-borders:focus {
    @include color($c3,1);
    @include background-color($c3,1);
}

.btn.no-bg.c4-borders:hover, .btn.no-bg.c4-borders:active, .btn.no-bg.c4-borders:focus {
    @include color($c3,1);
    @include background-color($c4,1);
}

.btn.no-bg.c5-borders:hover, .btn.no-bg.c5-borders:active, .btn.no-bg.c5-borders:focus {
    @include color($c3,1);
    @include background-color($c5,1);
}

.btn.no-bg.c6-borders:hover, .btn.no-bg.c6-borders:active, .btn.no-bg.c6-borders:focus {
    @include color($c3,1);
    @include background-color($c6,1);
}

.btn.c1-bg:hover, .btn.c1-bg:active, .btn.c1-bg:focus {
    @include color($c1,1);
    @include border-color($c1,1);
    @include background-color($c5,1);
}

.btn.c2-bg:hover, .btn.c2-bg:active, .btn.c2-bg:focus {
    @include color($c2,1);
    @include border-color($c2,1);
    @include background-color($c5,1);
}

.btn.c3-bg:hover, .btn.c3-bg:active, .btn.c3-bg:focus {
    @include color($c3,1);
    @include border-color($c3,1);
    @include background-color($c5,1);
}

.btn.c4-bg:hover, .btn.c4-bg:active, .btn.c4-bg:focus {
    @include color($c4,1);
    @include border-color($c4,1);
    @include background-color($c3,1);
}

.btn.c5-bg:hover, .btn.c5-bg:active, .btn.c5-bg:focus {
    @include color($c5,1);
    @include border-color($c5,1);
    @include background-color($c1,1);
}

.btn.c6-bg:hover, .btn.c6-bg:active, .btn.c6-bg:focus {
    @include color($c6,1);
    @include border-color($c6,1);
    @include background-color($c3,1);
}

.btn-primary:hover, .btn-primary:focus {
    color: #c6131a;
    background-color: #fff;
    border-color: #c6131a;
}
    /*	--------------------------------------------------
	5.0 LINKS
	-------------------------------------------------- */
    .link.text-uppercase .c1-borders:hover, .link.text-uppercase .btn.c1-borders:active, .link.text-uppercase .btn.c1-borders:focus, .link.text-uppercase .c2-borders:hover, .link.text-uppercase .btn.c2-borders:active, .link.text-uppercase .btn.c2-borders:focus, .link.text-uppercase .c4-borders:hover, .link.text-uppercase .btn.c4-borders:active, .link.text-uppercase .btn.c4-borders:focus, .link.text-uppercase .c5-borders:hover, .link.text-uppercase .btn.c5-borders:active, .link.text-uppercase .btn.c5-borders:focus {
    border-color: $c3;
    @include border-color($c3,1);
}

.link.text-uppercase .c3-borders:hover, .link.text-uppercase .btn.c3-borders:active, .link.text-uppercase .btn.c3-borders:focus {
    border-color: $c3;
    @include border-color($c3,1);
}



/*	--------------------------------------------------
	END
	-------------------------------------------------- */


