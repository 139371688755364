@import "variables";

/* 	--------------------------------------------------
    uSkinned.net

	LAYOUT
    -------------------------------------------------- */


/* 	--------------------------------------------------
	TABLE OF CONTENTS
    -------------------------------------------------- 

	1.0 GENERAL
	2.0 HEADER
	3.0 CONTENT
	4.0 FOOTER
		4.1 FOOTER NAV
	5.0 NAVIGATION
		5.1 MAIN NAV - MOVED
		5.3 TOP NAV - MOVED
		5.3 SUB - MOVED
		5.4 BREADCRUMB
		5.5 PAGINATION
		5.6 SITEMAP
		5.7 SOCIAL
		5.8 QUICK LINKS
		5.10 SLICK CAROUSEL NAVIGATION
	6.0 PROMO PODS
	7.0 LISTING
		7.1 BLOG LISTING
		7.2 SEARCH LISTING
		7.3 SECTION LISTING
		7.4 GRID LISTING
	8.0 SITE WIDE PODS
		8.1 BLOCKQUOTE POD
		8.2 CODE POD
		8.3 GENERAL POD
		8.4 IMAGE POD
		8.5 LISTING POD
		8.6 NEWSLETTER POD
		8.7 VIDEO POD
	9.0 STANDARD PAGE COMPONENTS
		9.1 TABBED
		9.2 ACCORDION
		9.3 QUOTES
		9.4 IMAGES
		9.5 GALLERY
		9.6 RESOURCES
		9.7 TABLES
		9.8 COMMENTS
		9.9 AUTHOR BIO
		9.10 IMAGE SLIDESHOW
		9.11 PRICING MENU
	10.0 ADVANCED PAGE COMPONENTS
	    10.1 STANDARD PAGE COMPONENTS ON ADVANCED PAGE
		10.2 BANNER - MOVED
			10.2.2 BANNER VIDEOS - MOVED
		10.3 CTA STRIP
    	10.4 PROMO PODS
	    10.5 TEXT WITH IMAGE / VIDEO
	    10.6 TEXT WITH SIGNUP
	    10.7 SIGNUP WITH LISTING
	    10.8 APC TABBED
	    10.9 GRID
		10.10 IMAGE CAROUSEL
		10.11 TEXT WITH LISTING
		10.12 TEXT WITH FORM
        10.14 FEATURED BLOGS
        10.15 POD WINDOWS
	11.0 FORMS
		11.1 SITE SEARCH
		11.2 AJAX LOADING
		11.3 COOKIES
	12.0 MISC
		12.1 OVERLAY ICONS
	
	
	COLOURS 
	
	c1	#c6131a		198,19,26
	c2	#646464		100,100,100
	c3	#000000		0,0,0
	c4	#f7f7f7		247,247,247
	c5 	#ffffff		255,255,255
	c6  #e1e1e1		225,225,225
	
*/

/*
	
	BOOTSTRAP BREAKPOINTS
	
	.col-xs-	< 768px		(container width 100%)
	.col-sm-	> 768px		(container width 750px)
	.col-md-	> 992px		(container width 970px)
	.col-lg-	> 1200px	(container width 1170px)
	
*/



/*	----------------------------------------------------------------------------------------------------
	1.0 GENERAL
	---------------------------------------------------------------------------------------------------- */

body {
    position: relative;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}

body:not(.no-header) {
    padding-top: 70px;
}

#site {
    position: relative;
    z-index: 2;
}

@media (min-width:768px) {
}

@media (min-width:992px) {
}

@media (min-width:1200px) {
}

@media (min-width:1600px) {

    body.fixed-width #site,
    body.fixed-width header {
        right: auto;
        left: auto;
        max-width: 1600px;
        margin: 0 auto;
    }
}

.skip {
    background: #fff;
    left: 0.5em;
    padding: 0.5em 0.75em;
    position: fixed;
    top: -1000px;
    transition: none;
    z-index: 3;
}

.skip:focus {
    top: 0.75em;
}


/*	----------------------------------------------------------------------------------------------------
	2.0 HEADER
	---------------------------------------------------------------------------------------------------- */

.alert-container {
    font-size: 14px;
    width: 100%;
    overflow: hidden;
    display: none;
    min-width: 320px;
    position: relative;
}

.alert-container > .container {
    padding-right: 130px;
    position: relative;
}

.alert-container .alert-close {
    cursor: pointer;
    position: absolute;
    right: 10px;
    padding: 0 15px;
    margin-right: 5px;
    top: 0;
    bottom: 0;
    color: $c5;
}

.alert-container .alert-close:hover, .alert-container .alert-close:focus {
    background-color: #000;
    color:$c1;
}

.alert-container .alert-close-icon {
    position: relative;
    display: table;
    height: 100%;
    width: 100%;
    text-align: center;
}

.alert-container .alert-close-icon-wrapper {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.alert-container .alert-close span {
    position: relative;
    font-size: 20px;
    top: -1px;
    margin-right: 0;
}

.alert-container .alert-close span + span {
    margin-left: -7px;
}

.alert-container .alert-text {
    margin: 15px 0;
}

.alert-container .alert-title {
    font-weight: 700;
    display: inline-block;
    vertical-align: baseline;
}

.alert-container .alert-title span {
    margin-right: 10px;
    vertical-align: middle;
    display: inline-block;
    font-size: 18px;
    color: #f0ad4e;
}

.alert-container .alert-message {
    display: inline-block;
    vertical-align: baseline;
    padding-right: 20px;
}

.alert-container .alert-message p {
    margin: 0;
    padding: 0;
}

.alert-container a {
    text-decoration: none;
}

.alert-container a:hover, .alert-container a:focus {
    text-decoration: underline;
}

.alert-container[data-alert-type="warning"] {
    background: #8c0000;
    color: $c5;

    .alert-close {
        color: white;

        &:hover, &:focus {
            background: $c1;
        }
    }

    .alert-title {
        color: white;

        i {
            color: #f0ad4e;
        }
    }

    a {
        color: #1b365d;
    }
}

.alert-container[data-alert-type="weather"] {
    background-color: #a72b2a;
    color: $c5;
}

.alert-container[data-alert-type="weather"] .alert-close {
    color: $c5;
    background-color: #740000;
}

.alert-container[data-alert-type="weather"] .alert-close:hover, .alert-container[data-alert-type="weather"] .alert-close:focus {
    color: $c5;
}

.alert-container[data-alert-type="weather"] .alert-title {
    color: $c5;
}

.alert-container[data-alert-type="weather"] .alert-title i {
    color: #1b365d;
    /*&:before {
                    content: '\f0e9';
                }*/
}

.alert-container[data-alert-type="weather"] a {
    color: #1b365d;
}

@media (min-width: 992px) {
    .login__menu {
        padding: 20px 10px;
        min-width: 214px;
        right: 0;
        left: auto;
    }
}

@media (max-width: 991px) and (min-width: 0) {
    .login__menu {
        position: relative;
        float: none;
        padding: 20px 10px;
        min-width: 214px;
        right: 0;
        left: auto;
    }

    nav.cta-link .login {
        display: none !important;
    }
}

header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 500;
    overflow: visible;

    .header-top {
        @include background-color($c1,1);
        min-height: 70px;
        display: block;
        width: 100%;
        position: relative;
    }

    .contact-band-header {
        @media (min-width:768px) {
            height: 45px;
            position: absolute;
            left: 0;
            right: 0;
            top: 65px;
            width: 100%;
            text-align: center;
            @include background-color(#8c0000,1);
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            align-content: center;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            -webkit-box-shadow: inset 1px 3px 5px 1px rgba(0,0,0,0.53);
            box-shadow: inset 1px 3px 5px 1px rgba(0,0,0,0.53);
            letter-spacing: .2em;

            > div:not(:last-child) {
                border-right: 1px solid #fff;
                margin-right: 30px;
                padding-right: 30px;
            }

            a {
                color: #fff;

                &:hover, &:focus {
                }
            }
        }

        @media (max-width:767px) {
            display: none;
        }
    }
}

html.reveal-out header {
    bottom: 0;
    height: 100%;
    max-height: 100%;
    overflow: auto;
}

html.nav-up header {
    top: -70px;
}

html.nav-down header {
    top: 0;
}
/*html.nav-down.reached-top header {
    background-color: rgba(000,000,000,0);
}*/
/*html.nav-down.reached-top.reveal-out header {
    background-color: #000;
    background-color: rgba(000,000,000,1);
}*/
header .container {
    width: 100%;
}

header #logo {
    position: absolute;
    top: 17.5px;
    left: 15px;
    z-index: 501;


    &.no-image {
        top: 23px;
    }

    a {
        display: block;
        text-indent: -9999px;
        color: $c5;
    }

    &.no-image a {
        text-indent: 0;
        font-size: 30px;

        &:active {
            position: relative;
            top: 1px;
        }
    }
}

header .cta-link {
    position: absolute;
    top: 15px;
    right: 60px;
    z-index: 502;

    .btn {
        height: 40px;
        padding: 9px 15px;
        padding-left: 2.5em;
        padding-right: 2.5em;
    }
}


header,
.navigation,
.navigation .reveal {
}

header a.expand {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 502;
    width: 60px;
    height: 70px;
    display: block;
    text-indent: -9999px;
    text-decoration: none;
    cursor: pointer;

    &:hover, &:focus {
        span {
            background-color: $c7;
        }
    }

    em {
        display: none;
        -webkit-transition: none;
        -moz-transition: none;
        -ms-transition: none;
        transition: none;
    }

    span {
        display: block;
        width: 30px;
        height: 2px;
        position: absolute;
        right: 15px;
        background-color: $c5;
        @include background-color($c5,1);
        -webkit-opacity: 1;
        -moz-opacity: 1;
        -ms-opacity: 1;
        opacity: 1;
        -webkit-transition: transform 0.3s ease-in-out 0s, opacity 0.2s ease-in-out 0.3s, left 0.3s ease-in-out 0.3s, top 0.3s ease-in-out 0.3s, width 0.3s ease-in-out 0.3s;
        -moz-transition: transform 0.3s ease-in-out 0s, opacity 0.2s ease-in-out 0.3s, left 0.3s ease-in-out 0.3s, top 0.3s ease-in-out 0.3s, width 0.3s ease-in-out 0.3s;
        -ms-transition: transform 0.3s ease-in-out 0s, opacity 0.2s ease-in-out 0.3s, left 0.3s ease-in-out 0.3s, top 0.3s ease-in-out 0.3s, width 0.3s ease-in-out 0.3s;
        transition: transform 0.3s ease-in-out 0s, opacity 0.2s ease-in-out 0.3s, left 0.3s ease-in-out 0.3s, top 0.3s ease-in-out 0.3s, width 0.3s ease-in-out 0.3s;

        &.top {
            top: 26px;
        }

        &.mid {
            top: 34px;
        }

        &.bottom {
            top: 42px;
        }
    }

    &.active span {
        -webkit-transition: transform 0.3s ease-in-out 0.2s, opacity 0.2s ease-in-out 0s, left 0.3s ease-in-out 0s, top 0.3s ease-in-out 0.3s, width 0.3s ease-in-out 0s;
        -moz-transition: transform 0.3s ease-in-out 0.2s, opacity 0.2s ease-in-out 0s, left 0.3s ease-in-out 0s, top 0.3s ease-in-out 0.3s, width 0.3s ease-in-out 0s;
        -ms-transition: transform 0.3s ease-in-out 0.2s, opacity 0.2s ease-in-out 0s, left 0.3s ease-in-out 0s, top 0.3s ease-in-out 0.3s, width 0.3s ease-in-out 0s;
        transition: transform 0.3s ease-in-out 0.2s, opacity 0.2s ease-in-out 0s, left 0.3s ease-in-out 0s, top 0.3s ease-in-out 0.3s, width 0.3s ease-in-out 0s;


        &.top {
            transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
        }

        &.bottom {
            transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
        }



        &.mid {
            width: 0;
        }

        &.top,
        &.bottom {
            top: 34px;
        }
    }

    header a.expand:focus {
        outline: 2px dotted !important;
        color: white;
    }

    header a.expand:hover span, header a.expand:focus span {
        @include background-color($c1,1);
    }
}

html.reveal-out header a.expand:hover span, html.reveal-out header a.expand:focus span {
    background-color: $c7;
}


@media (min-width:0) and (max-width:767px) {
}

@media (min-width:768px) {
}

@media (min-width:992px) {
}

@media (min-width:1200px) {
}
/*	----------------------------------------------------------------------------------------------------
	3.0 CONTENT
	---------------------------------------------------------------------------------------------------- */
.content {
    padding: 25px 0;
}

@media (max-width:767px) {

    .content #left-col,
    .content #right-col,
    .content #mid-col {
        padding-bottom: 30px;
    }
}

@media (min-width:768px) {

    .content {
        padding: 30px 0;
    }
}

@media (min-width:992px) {

    .content {
        padding: 45px 0;
    }
}

@media (min-width:1200px) {

    .content {
        padding: 60px 0;
    }
}
/*	----------------------------------------------------------------------------------------------------
	4.0 FOOTER
	---------------------------------------------------------------------------------------------------- */
footer {
    padding: 25px 0;

    .custom-footer {
        a.btn {
            text-align: center;
            font-weight: 600;
            text-decoration: none;
            padding: 15px;
            display: block;

            &:hover, &:focus {
                text-decoration: none !important;
            }
        }

        .contact {
            text-align: center;
            font-size: 18px;
            font-weight: 800;
        }
    }

    .footer-branding {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        @media (max-width:768px) {
            flex-direction: column;
        }

        img {
            @media (min-width:768px) {
                max-width: 60%;
                margin-right: 40px;
            }
        }

        .social {
            @media (max-width:767px) {
                margin: 25px auto;
            }
        }
    }
}

nav.footer {
    text-align: center;
}

footer .company-details,
footer .quick-links {
    margin-bottom: 30px;
}

@media (min-width:768px) {

    footer .company-details,
    footer .quick-links {
        margin-bottom: 0;
    }
}

@media (min-width:768px) and (max-width:991px) {

    footer {
        padding: 30px 0;
    }
}

@media (min-width:992px) {

    footer {
        padding: 45px 0 80px;
    }

    footer h3.heading {
        font-size: 22px;
    }
}

@media (min-width:1200px) {

    footer {
        padding: 60px 0 80px;

        a {
            &:focus, &:hover {
                font-weight: bold;
            }
        }
    }
}

footer a:hover, footer a:focus, footer a:active {
    color: $c3;
    text-decoration: underline !important;
}
/*  --------------------------------------------------
    5.4 BREADCRUMB NAVIGATION             
    -------------------------------------------------- */
.breadcrumb-trail {
    overflow: hidden;
    height: 60px;
}

.c5-bg + .breadcrumb-trail {
    border-width: 1px 0 0;
    border-style: solid;
    border-color: $c6;
    @include border-color($c6,1);
}

.breadcrumb-trail ol {
    padding: 0;
    margin: 0;
    background: transparent;
    font-size: 14px;
}

.breadcrumb-trail ol li {
    position: relative;
    display: inline-block;
}

.breadcrumb-trail ol li.active {
    opacity: 0.6;
}

.breadcrumb-trail ol li a {
    display: inline-block;
    height: 60px;
    color: $c3;
    @include color($c3,1);
}

html.no-touch .breadcrumb-trail ol li a:hover, html.no-touch .breadcrumb-trail ol li a:focus {
    color: $c2;
    @include color($c2,1);
}

.breadcrumb-trail ol li span {
    padding: 23px 40px 0 10px;
    display: block;
    height: 60px;
}

.breadcrumb-trail ol li:first-child span {
    padding-left: 0;
}

.breadcrumb-trail ol li::before,
.breadcrumb-trail ol li::after {
    content: "";
    position: absolute;
    top: -7px;
    right: 15px;
    width: 1px;
    height: 40px;
    background-color: $c6;
    @include background-color($c6,1);
    -webkit-transform: rotate(-30deg);
    -moz-transform: rotate(-30deg);
    -ms-transform: rotate(-30deg);
    transform: rotate(-30deg);
}

.breadcrumb-trail ol li::after {
    top: auto;
    bottom: -7px;
    -webkit-transform: rotate(30deg);
    -moz-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
}

.breadcrumb-trail ol li.active::before,
.breadcrumb-trail ol li.active::after {
    display: none;
}

@media (min-width:768px) {
}

@media (min-width:992px) {
}

@media (min-width:1200px) {
}
/*  --------------------------------------------------
    5.5 PAGINATION NAVIGATION             
    -------------------------------------------------- */
nav#pagination {
    padding: 30px 0;
}

nav#pagination.post {
}

nav#pagination .pagination {
    position: relative;
    overflow: hidden;
    min-height: 50px;
    margin: 0;
    display: block;
    font-size: 0;
}

.listing.shop nav#pagination {
    margin: 0;
}

.listing.shop nav#pagination .pagination {
    border: 0 none;
}

nav#pagination .pagination li {
    border: 0 none;
    display: inline-block;
    font-size: 14px;
    margin: 0 2px;
}

nav#pagination .pagination li.prev,
nav#pagination .pagination li.next {
    position: absolute;
    top: 0;
    left: 0;
}

nav#pagination .pagination li.next {
    right: 0;
    left: auto;
}

nav#pagination .pagination li a {
    border-width: 1px;
    border-style: solid;
    border-color: $c6;
    @include border-color($c6,1);
    padding: 13px 20px;
    color: #fff;
    @include background-color($c1,1);
}

html.no-touch nav#pagination .pagination li:not(.active) a {
    &:hover, &:focus {
        color: $c5;
        @include color($c5,1);
        border-color: #c6131a;
        @include border-color($c2,1);
        @include background-color($c2,1);
    }
}

nav#pagination .pagination li.prev,
nav#pagination .pagination li.next {
    margin: 0;
}

nav#pagination .pagination li.active a {
    font-weight: bold;
    color: $c5;
    @include color($c5,1);
    border-color: $c3;
    @include border-color($c3,1);
    background-color: $c3;
    @include background-color($c3,1);
}

@media (min-width:768px) {
}

@media (min-width:992px) {
}

@media (min-width:1200px) {
}
/*  --------------------------------------------------
    5.6 SITEMAP NAVIGATION             
    -------------------------------------------------- */
nav.sitemap {
    overflow: hidden;
    height: 100%;
    position: relative;
}

nav.sitemap ul {
    margin: 0;
    padding: 0 0 8px;
}

nav.sitemap ul li {
    position: relative;
    display: block;
    padding: 0;
    margin: 0;
}

nav.sitemap ul li::before,
nav.sitemap ul li::after {
    content: "";
    position: absolute;
    left: 0;
    display: block;
    background-color: $c6;
    @include background-color($c6,1);
}

nav.sitemap ul li::before {
    top: 0;
    bottom: 0;
    width: 1px;
    height: 100%;
}

nav.sitemap ul li::after {
    top: 15px;
    width: 5px;
    height: 1px;
}

nav.sitemap ul ul {
    margin-left: 16px;
}

@media (min-width:768px) {
}

@media (min-width:992px) {
}

@media (min-width:1200px) {
}
/*  --------------------------------------------------
    5.7 SOCIAL NAVIGATION        
    -------------------------------------------------- */
nav.social {

    @media (min-width:992px) {
        text-align: right;
    }
}

nav.social ul {
    line-height: 0;
}

footer nav.social ul {
    padding-top: 5px;
}

nav.social ul li {
    display: inline-block;
    position: relative;
    margin: 0 2px 6px 0;
}

nav.social ul li a {
    display: block;
    float: left;
    text-indent: -9999px;
    width: 40px;
    height: 40px;
    background-image: url('/images/icons/social.png');
    background-repeat: no-repeat;
    background-size: 40px auto;
}

html.no-touch nav.social ul li a:hover, html.no-touch nav.social ul li a:focus {
    opacity: 0.6;
}

nav.social ul li.facebook a {
    background-position: left top;
}

nav.social ul li.twitter a {
    background-position: left -40px;
}

nav.social ul li.linkedin a {
    background-position: left -80px;
}

nav.social ul li.google_plus a {
    background-position: left -120px;
}

nav.social ul li.pinterest a {
    background-position: left -160px;
}

nav.social ul li.instagram a {
    background-position: left -200px;
}

nav.social ul li.flickr a {
    background-position: left -240px;
}

nav.social ul li.tumblr a {
    background-position: left -280px;
}

nav.social ul li.vimeo a {
    background-position: left -320px;
}

nav.social ul li.youtube a {
    background-position: left -360px;
}

nav.social ul li.dribble a {
    background-position: left -400px;
}

nav.social ul li.skype a {
    background-position: left -440px;
}

nav.social ul li.rss a {
    background-position: left -480px;
}

nav.social ul li.soundcloud a {
    background-position: left -560px;
}

nav.social ul li.behance a {
    background-position: left -600px;
}

@media (min-width:768px) {
}

@media (min-width:992px) {
}

@media (min-width:1200px) {
}
/*  --------------------------------------------------
    5.8 QUICK LINKS           
    -------------------------------------------------- */
.quick-links {
}

.quick-links ul {
}

footer .quick-links ul {
    padding-top: 4px;
}

.quick-links ul li {
}

.quick-links ul li a {
    display: block;
    padding-left: 10px;
    padding-bottom: 15px;
}

html.no-touch .quick-links ul li a:hover, html.no-touch .quick-links ul li a:focus {
    padding-left: 15px;
}

.quick-links ul li a::after {
    content: "";
    position: absolute;
    top: 8px;
    left: 15px;
    display: block;
    width: 5px;
    height: 1px;
    @include background-color($c3,0.3);
}

html.no-touch .quick-links ul li a:hover::after, html.no-touch .quick-links ul li a:focus::after {
    width: 10px;
    @include background-color($c3,1);
}

footer .quick-links ul li a::after {
    @include background-color($c5,0.3);
}

html.no-touch footer .quick-links ul li a:hover::after, html.no-touch footer .quick-links ul li a:focus::after {
    @include background-color($c5,1);
}

@media (min-width:768px) {
}

@media (min-width:992px) {
}

@media (min-width:1200px) {
}
/*  --------------------------------------------------
    5.9 FOOTER NAVIGATION             
    -------------------------------------------------- */
nav.footer {
    padding: 80px 0 0;
}

nav.footer ul {
    font-size: 13px;
}

nav.footer ul li {
    display: block;
    padding: 10px 0;
}

nav.footer ul li a,
nav.footer ul li div {
    display: inline;
    padding: 0;
}

nav.footer ul li.site-credit a {
    font-weight: 600;
}

@media (min-width:768px) {

    nav.footer ul li {
        margin: 0 8px 0 0;
        padding: 0;
        display: inline-block;
    }

    nav.footer ul li.site-credit {
        clear: both;
        display: block;
        padding: 40px 0 0;
    }
}

@media (min-width:768px) and (max-width:991px) {
}

@media (min-width:992px) {

    nav.footer ul li.site-credit {
        float: right;
        clear: none;
        padding: 0;
    }
}
/*  --------------------------------------------------
    5.10 SLICK CAROUSEL NAVIGATION            
    -------------------------------------------------- */
.slick-prev, .slick-next {
    display: table;
}

.slick-prev > span, .slick-next > span {
    position: relative;
    margin: 0 !important;
    display: block;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
}

.slick-prev, .slick-next {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    z-index: 200;
    background: none;
    border: 0 none;
    cursor: pointer;
    @include background-color($c3,0.6);
    color: $c5;
    @include color($c5,1);
    transform: translateY(-50%);
}

.slick-prev {
    left: 0;
    @include background-color($c3,0.4);
}

.slick-next {
    right: 0;
}

html.no-touch .slick-prev:hover, html.no-touch .slick-next:hover, html.no-touch .slick-prev:focus, html.no-touch .slick-next:focus {
    @include background-color($c3,1);
}

.slick-prev > span, .slick-next > span {
    font-size: 20px;
}

.banner .slick-prev, .banner .slick-next {
    top: auto;
    bottom: 250px;
    transform: none;
}

.banner .slick-next {
    right: 0;
}

ul.slick-dots {
    position: absolute;
    bottom: 15px;
    left: 15px;
    width: 100%;
    margin: 0;
    text-align: left;
}

ul.slick-dots li {
    margin: 0 10px 0 0;
    display: inline-block;
    width: 10px;
    height: 10px;
}

ul.slick-dots li button {
    color: #000;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    border: 1px solid #000;
    @include background-color($c3,0.4);
    -webkit-transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
    -moz-transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
    -ms-transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
    transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
}

ul.slick-dots li.slick-active button {
    background-color: $c3;
    @include background-color($c3,1);
}

.banner ul.slick-dots li button,
.c1-bg ul.slick-dots li button, .c2-bg ul.slick-dots li button, .c3-bg ul.slick-dots li button {
    @include background-color($c5,0.4);
}

.banner ul.slick-dots li.slick-active button,
.c1-bg ul.slick-dots li.slick-active button, .c2-bg ul.slick-dots li.slick-active button, .c3-bg ul.slick-dots li.slick-active button {
    background-color: $c5;
    @include background-color($c5,1);
}

.image-carousel ul.slick-dots {
    position: relative;
    bottom: auto;
    top: 19px;
    display: none !important;
}

@media (min-width:768px) {

    .image-carousel .slick-prev, .image-carousel .slick-next {
        top: 35%;
    }
}


@media (min-width:992px) {

    .banner .slick-prev, .banner .slick-next {
        width: 70px;
        height: 70px;
    }

    .slick-prev > span, .slick-next > span {
        font-size: 30px;
    }

    .image-carousel ul.slick-dots {
        display: block !important;
    }
}
/*  ----------------------------------------------------------------------------------------------------
    7.0 LISTING   
    ---------------------------------------------------------------------------------------------------- */
.listing {
    position: relative;
    height: 100%;
}

.apc .listing {
    padding: 0;
    margin: 0;
}

.listing .item {
    margin-bottom: 30px;
}

.listing .item a {
    &:hover, &:focus {
        color: $c3;
    }
}

.listing .item .image {
    margin-bottom: 15px;
}

.listing .item .heading {
    display: block;
    font-size: 18px;
    margin: 0;
}

.listing .item .text {
    display: block;
    margin-top: 10px;
}

.listing .item .text :first-child {
    margin-top: 0;
}

.listing .item .text :last-child {
    margin-bottom: 0;
}

.listing .item .text a {
    display: inline;
}

html.no-touch .listing .item .text a:hover, html.no-touch .listing .item .text a:focus {
    text-decoration: underline;
}

.listing .item .link {
    margin: 20px 0 0;
}

.listing .item .link a {
}


@media (max-width:767px) {

    .spc.listing .item .pull-right {
        float: none;
        text-align: left;
    }
}

@media (min-width:768px) {
}

@media (min-width:992px) {

    .listing .item .heading {
        font-size: 20px;
    }
}

@media (min-width:1200px) {

    .listing .item .heading {
        font-size: 22px;
    }
}
/*  --------------------------------------------------
    7.1 BLOG LISTING   
    -------------------------------------------------- */
.listing.blog {
}

.blog .item {
    padding: 10px;
    border: 2px solid #b40000;
}
.blog .item .heading {
    background: #b40000;
    color: white;
    padding: 10px;
    &:hover,&:focus{
        text-decoration: underline;
    }
}
.filter .inner a {
    background: #b40000;
    color: white;
    border-bottom: 1px solid white;

    &:hover, &:focus {
        background: white;
        color: black;
        border-bottom: 1px solid #b40000;
    }
}

    .listing.blog .item .inner .image {
        margin: -15px -15px 15px;
    }

    .c5-bg .listing.blog .item .inner {
        padding: 0 !important;
    }

    .c5-bg .listing.blog .item .inner .image {
        margin: 0 0 15px;
    }

    .listing.blog .info + div.meta,
    .listing.blog a + div.meta {
        margin-top: 20px;
    }

    .listing.blog .item a:hover p, .listing.blog .item a:focus p {
        color: #c6131a;
        font-weight: 600;
    }

    .listing.blog .item a:focus p {
        outline: 2px dotted currentcolor;
    }


    div.meta {
    }

    .spc .meta {
        padding: 20px 0;
        margin-top: 40px;
        border-width: 1px 0 0;
        border-style: solid;
        border-color: $c6;
        @include border-color($c6,1);
    }

    .apc .spc .meta {
        margin-top: 0;
    }

    .meta a {
        display: inline;
    }

    html.no-touch .meta a:hover, html.no-touch .meta a:focus {
        text-decoration: underline;
    }

    .listing div.meta {
        font-size: 12px;
    }

    .meta .author-date {
        padding-bottom: 10px;
    }

    div.meta .inner {
    }

    .item div.meta {
        margin-top: 30px;
    }

    div.meta .row {
    }

    div.meta .comments {
        padding-bottom: 10px;
    }

    @media (min-width:580px) and (max-width:767px) {

        .listing.blog .item.col-sm-6 {
            width: 50%;
        }

        .listing.blog .item:nth-of-type(2n+1) {
            clear: both;
        }
    }

    @media (min-width:768px) {
    }

    @media (min-width:768px) and (max-width:991px) {

        .listing.blog .item:nth-of-type(2n+1) {
            clear: both;
        }

        .apc.featured-blogs .listing.blog .item:nth-of-type(2n+1) {
            clear: none;
        }
    }

    @media (min-width:992px) {

        .listing.blog .item .inner .image {
            margin: -20px -20px 20px;
        }

        .c5-bg .listing.blog .item .inner .image {
            margin: 0 0 20px;
        }

        #left-col .listing.blog .item:nth-of-type(2n+1),
        #right-col .listing.blog .item:nth-of-type(2n+1),
        #mid-col .listing.blog .item:nth-of-type(2n+1) {
            clear: both;
        }

        #wide-col .listing.blog .item.col-sm-6 {
            width: 33.3333333%;
        }

        #wide-col .listing.blog .item:nth-of-type(3n+1) {
            clear: both;
        }

        .listing.blog .item .heading {
            font-size: 20px;
        }

        .listing.blog .item.col-sm-6 .heading {
            font-size: 20px;
        }

        .listing.blog .item:not(.col-sm-6) .meta .comments,
        .listing.blog .item:not(.col-sm-6) .meta .categories,
        .spc .meta .comments,
        .spc .meta .categories {
            width: 50%;
            float: left;
        }

        .listing.blog .item:not(.col-sm-6) .meta .categories,
        .spc .meta .categories {
            text-align: right;
        }
    }

    @media (min-width:1200px) {

        .listing.blog .item .inner .image {
            margin: -30px -30px 30px;
        }

        .c5-bg .listing.blog .item .inner .image {
            margin: 0 0 30px;
        }

        .listing.blog .item .heading {
            font-size: 24px;
        }
    }
    /*  --------------------------------------------------
    7.2 SEARCH LISTING   
    -------------------------------------------------- */
    .listing.search {
        margin-top: 30px;
    }

    .listing.search .item {
    }

    .listing.search .item .link {
        margin-top: 10px;
        font-size: 12px;
    }

    @media (min-width:768px) {
    }

    @media (min-width:992px) {
    }

    @media (min-width:1200px) {
    }
    /*  --------------------------------------------------
    7.3 SECTION LISTING   
    -------------------------------------------------- */
    .listing.section:not(.grid) {
        margin-top: 30px;

        .info {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            padding: 15px 0;
            border-bottom: 2px solid $c6;

            @media (max-width:768px) {
                flex-direction: column;
                align-items: flex-start;

                .btn {
                    margin-top: 15px;
                }
            }

            .heading {
                font-size: 24px;
            }
        }
    }

    .listing.section:not(.grid) .item {
    }

    .listing.section:not(.grid) .item .image {
        margin-bottom: 5px;
    }

    .listing.section:not(.grid) .heading {
        margin-top: 10px;
    }

    @media (min-width:768px) {
    }

    @media (min-width:992px) {
    }

    @media (min-width:1200px) {
    }
    /*  --------------------------------------------------
    7.4 GRID LISTING   
    -------------------------------------------------- */
    .listing.grid {
        padding: 15px;
    }

    .listing.grid .item {
        overflow: hidden;
        text-align: center;
        padding: 0;
        margin: 0;
    }

    .listing.grid .item .image {
        margin: 0;
    }

    .listing.grid .item .info {
        position: absolute; /* right:0; bottom:0; left:0; */
        right: 0;
        bottom: 0;
        left: 0;
        padding: 15px;
        @include background-color($c1,0.8);
    }

    html.no-touch .listing.grid .item .info {
        top: 0;
    }

    html.no-touch .listing.grid .item a .info {
        @include background-color($c1,0.3);
        opacity: 0;
        -webkit-transform: scale(1.25);
        -moz-transform: scale(1.25);
        -ms-transform: scale(1.25);
        -o-transform: scale(1.25);
        transform: scale(1.25);
    }

    html.no-touch .listing.grid .item a:hover .info, html.no-touch .listing.grid .item a:focus .info {
        @include background-color($c1,0.8);
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }

    .listing.grid .item .info .heading + .text {
        margin-top: 10px;
    }

    @media (min-width:620px) and (max-width:767px) {

        .listing.grid .item.col-sm-6 {
            width: 50%;
            float: left;
        }

        .listing.grid .item:nth-of-type(2n+1) {
            clear: both;
        }
    }

    @media (min-width:768px) {
    }

    @media (min-width:768px) and (max-width:991px) {

        .listing.grid .item:nth-of-type(2n+1) {
            clear: both;
        }
    }

    @media (min-width:992px) {

        #left-col .listing.grid .item:nth-of-type(2n+1),
        #right-col .listing.grid .item:nth-of-type(2n+1),
        #mid-col .listing.grid .item:nth-of-type(2n+1) {
            clear: both;
        }

        #wide-col .listing.grid .item.col-sm-6 {
            width: 33.3333333%;
        }

        #wide-col .listing.grid .item:nth-of-type(3n+1) {
            clear: both;
        }

        .listing.grid .item .info {
            padding: 20px;
        }
    }

    @media (min-width:1200px) {

        .listing.grid .item .info {
            padding: 30px;
        }
    }
    /*  ----------------------------------------------------------------------------------------------------
    8.0 SITE WIDE PODS
    ---------------------------------------------------------------------------------------------------- */
    .swp {
        margin-bottom: 30px;
    }

    .swp .item {
        position: relative;
        margin-bottom: 30px;
    }

    .swp .item:last-child {
        margin-bottom: 0;
    }

    .swp .item .heading a {
        text-decoration: none;
    }

    .swp .item .heading i {
        font-size: 15px;
        display: none;
    }

    .swp .item .link {
        margin-bottom: 0;
    }
    /*  ----------------------------------------------------------------------------------------------------
    8.1 BLOCKQUOTE POD
    ---------------------------------------------------------------------------------------------------- */
    .swp .item.blockquote-pod {
    }

    .swp blockquote p {
        font-size: 16px;
    }

    @media (min-width:768px) {
    }

    @media (min-width:992px) {

        .swp blockquote p {
            font-size: 20px;
        }
    }

    @media (min-width:1200px) {
    }
    /*  ----------------------------------------------------------------------------------------------------
    8.2 CODEPOD
    ---------------------------------------------------------------------------------------------------- */
    .swp .item.code-pod {
    }
    /*  ----------------------------------------------------------------------------------------------------
    8.3 GENERAL POD
    ---------------------------------------------------------------------------------------------------- */
    .swp .item.general-pod {
    }
    /*  ----------------------------------------------------------------------------------------------------
    8.4 IMAGE POD
    ---------------------------------------------------------------------------------------------------- */
    .swp .item.image-pod {
    }
    /*  ----------------------------------------------------------------------------------------------------
    8.5 LISTING POD
    ---------------------------------------------------------------------------------------------------- */
    .swp .item.listing-pod {
    }

    .swp .item.listing-pod .inner > .heading {
        margin-bottom: 30px;
    }

    .swp .item.listing-pod .link {
        margin: 20px 0 0;
    }

    .swp .listing {
        margin: 20px 0 0;
        padding: 0;
    }

    .swp .listing .item {
        margin: 0;
        padding: 0;
    }

    .swp .listing .item::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        margin: 10px 0 15px;
        background-color: $c6;
        @include background-color($c6,1);
    }

    .swp .listing .item .heading {
        font-size: 16px;
        color: $c3;
        @include color($c3,1);
    }

    .swp .listing .item .heading a {
        color: $c3;
        @include color($c3,1);
    }

    html.no-touch .swp .listing .item .heading a:hover, html.no-touch .swp .listing .item .heading a:focus {
        @include color($c3,0.7);
    }

    .swp .listing .item .meta {
        margin: 5px 0 0;
        padding: 0;
        font-size: 10px;
    }
    /*  ----------------------------------------------------------------------------------------------------
    8.6 NEWSLETTER POD
    ---------------------------------------------------------------------------------------------------- */
    .swp .item.newsletter-pod {
    }
    /*  ----------------------------------------------------------------------------------------------------
    8.7 VIDEO POD
    ---------------------------------------------------------------------------------------------------- */
    .swp .item.video-pod {
    }
    /*  ----------------------------------------------------------------------------------------------------
    9.0 STANDARD PAGE COMPONENTS
    ---------------------------------------------------------------------------------------------------- */
    .spc {
        position: relative;
        margin-bottom: 30px;
    }

    .spc.forms .inner.c5-bg {
        padding: 0;
    }
    /*  --------------------------------------------------
    9.1 TABBED
    -------------------------------------------------- */
    .tabbed {
    }

    nav.tabs .nav {
        border: 0 none;
    }

    nav.tabs .nav li {
    }

    .spc nav.tabs .nav li:first-child {
        margin-left: 0;
    }

    .spc nav.tabs .nav li:last-child {
        margin-right: 0;
    }

    nav.tabs .nav li a {
        padding: 10px 15px;
        background: none;
        min-width: 50px;
        border-width: 5px 1px 1px !important;
        border-color: $c6;
        @include border-color($c6,1);
        background-color: $c4;
        @include background-color($c4,1);
    }

    html.no-touch nav.tabs .nav li:not(.active) a:hover {
        border-top-color: #c6131a;
        @include border-top-color($c1,1);
        background-color: $c5;
        @include background-color($c5,1);
    }

    nav.tabs .nav li.active a {
        color: $c3;
        @include color($c3,1);
        border-top-color: #c6131a;
        @include border-top-color($c1,1);
        border-bottom-color: $c5;
        @include border-bottom-color($c5,1);
        background-color: $c5;
        @include background-color($c5,1);
    }

    .apc.c1-bg nav.tabs .nav li a, .apc.c2-bg nav.tabs .nav li a .apc.c3-bg nav.tabs .nav li a {
        border-width: 5px 0 0 !important;
    }

    html.no-touch .apc.c1-bg nav.tabs .nav li:not(.active) a:hover,
    .apc.c1-bg nav.tabs .nav li.active a, html.no-touch .apc.c1-bg nav.tabs .nav li:not(.active) a:focus {
        border-top-color: $c2;
        @include border-top-color($c2,1);
    }


    .tabbed .tab-content {
        padding: 20px;
        border-width: 1px;
        border-style: solid;
        border-color: $c6;
        @include border-color($c6,1);
    }

    .apc.c1-bg .tabbed .tab-content,
    .apc.c2-bg .tabbed .tab-content,
    .apc.c3-bg .tabbed .tab-content {
        border-width: 0 !important;
    }

    .spc.tabbed .tab-content {
        background-color: $c5;
        @include background-color($c5,1);
    }


    @media (min-width:768px) {
    }

    @media (min-width:992px) {

        nav.tabs .nav {
            font-size: 18px;
        }
    }

    @media (min-width:1200px) {

        nav.tabs .nav {
            font-size: 20px;
        }
    }
    /*  --------------------------------------------------
    9.2 ACCORDION
    -------------------------------------------------- */
    .accordion {
    }

    .accordion .heading {
        margin: 5px 0 0;
    }

    .accordion .heading a {
        position: relative;
        display: block;
        padding: 15px 45px 15px 15px;
        background-color: $c5;
        @include background-color($c5,1);
        border-width: 1px 1px 1px 5px;
        border-style: solid;
        border-color: $c6;
        @include border-color($c6,1);
        text-decoration: none !important;
    }

    .accordion .heading a:not(.collapsed),
    html.no-touch .accordion .heading a.collapsed:hover, html.no-touch .accordion .heading a.collapsed:focus {
        color: $c3;
        @include color($c3,1);
        border-left-color: #c6131a;
        @include border-left-color($c1,1);
    }

    .c1-bg .accordion .heading a:not(.collapsed),
    html.no-touch .c1-bg .accordion .heading a.collapsed:hover, html.no-touch .c1-bg .accordion .heading a.collapsed:focus {
        border-left-color: $c2;
        @include border-left-color($c2,1);
    }

    .accordion .heading a.collapsed {
        border-color: $c6;
        @include border-color($c6,1);
    }

    .accordion .heading i {
        font-size: 15px;
        position: absolute;
        top: 15px;
        right: 15px;
    }

    .accordion .heading a:not(.collapsed) i.ion-minus,
    .accordion .heading a.collapsed i.ion-plus {
        display: block;
    }

    .accordion .heading a.collapsed i.ion-minus,
    .accordion .heading a:not(.collapsed) i.ion-plus {
        display: none;
    }

    .accordion .panel-body {
        padding: 15px 0;
    }

    .apc .spc.accordion .panel-body {
        padding: 15px;
        border-width: 0 1px 1px 5px;
        border-style: solid;
        border-color: $c6;
        @include border-color($c6,1);
        background-color: $c5;
        @include background-color($c5,1);
    }


    @media (min-width:768px) {
    }

    @media (min-width:992px) {

        .accordion .heading {
            font-size: 18px;
        }
    }

    @media (min-width:1200px) {

        .accordion .heading {
            font-size: 20px;
        }
    }
    /*  --------------------------------------------------
    9.3 QUOTES
    -------------------------------------------------- */
    .spc.quotes {
    }

    .spc.quotes blockquote p {
        font-size: 22px;
    }

    .apc.standard-components .spc.quotes blockquote {
        text-align: center;
    }

    .apc.standard-components .spc.quotes blockquote::before {
        left: auto;
        margin-left: -10px;
    }

    .apc.standard-components .spc.quotes blockquote::after {
        display: none;
    }

    @media (min-width:768px) {
    }

    @media (min-width:992px) {

        .spc.quotes blockquote {
            padding-top: 40px;
        }

        .quotes blockquote::before {
            font-size: 40px;
        }

        .quotes blockquote::after {
            font-size: 30px;
        }

        .spc.quotes blockquote p {
            font-size: 28px;
        }

        .spc.quotes blockquote cite {
            font-size: 15px;
        }

        .apc.standard-components .spc.quotes blockquote p {
            font-size: 35px;
        }
    }

    @media (min-width:1200px) {

        .spc.quotes blockquote p {
            font-size: 35px;
        }

        .apc.standard-components .spc.quotes blockquote::before {
            font-size: 50px;
        }

        .apc.standard-components .spc.quotes blockquote {
            padding-top: 50px;
        }

        .apc.standard-components .spc.quotes blockquote p {
            font-size: 40px;
        }
    }
    /*  --------------------------------------------------
    9.4 IMAGES
    -------------------------------------------------- */
    .spc.image {
        margin-bottom: 16px;
    }

    .spc.image img {
        display: block;
        overflow: hidden;
        max-width: 100%;
        height: auto;
    }

    .spc.image .info {
    }

    .spc.image .info .text {
        margin: 10px 0 0;
        font-size: 90%;
        font-style: italic;
        -webkit-opacity: 0.81;
        -moz-opacity: 0.81;
        -ms-opacity: 0.81;
        opacity: 0.81;
    }
    /*  --------------------------------------------------
    9.5 GALLERY        
    -------------------------------------------------- */
    .spc.gallery {
        margin-bottom: 15px;
    }

    .gallery .item {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .gallery .item a {
    }

    .gallery .item .image {
        position: relative;
    }


    @media (max-width:991px) {

        .spc.gallery .item:nth-of-type(2n+1) {
            clear: both;
        }
    }

    @media (min-width:992px) {

        .spc.gallery .item:nth-of-type(3n+1) {
            clear: both;
        }
    }

    @media (min-width:1200px) {
    }
    /*  --------------------------------------------------
    9.6 RESOURCES         
    -------------------------------------------------- */
    .resources {
    }

    .resources .item {
        padding: 15px;
        border-width: 1px 1px 1px 5px;
        border-color: $c6;
        @include border-color($c6,1);
        background-color: $c5;
        @include background-color($c5,1);
    }

    html.no-touch .resources .item:hover, html.no-touch .resources .item:focus {
        border-left-color: #c6131a;
        @include border-left-color($c1,1);
        background-color: $c4;
        @include background-color($c4,1);
    }

    html.no-touch .c1-bg .resources .item:hover, html.no-touch .c1-bg .resources .item:focus {
        border-left-color: $c2;
        @include border-left-color($c2,1);
    }

    .resources .item .heading {
        display: block;
        font-size: 16px;
        margin-bottom: 4px;
        color: #c6131a;
        @include background-color($c1,1);
    }

    .resources .item .size {
        font-size: 12px;
    }

    @media (min-width:768px) {
    }

    @media (min-width:992px) {

        .resources .item {
            padding: 20px;
        }

        .resources .item .heading {
            font-size: 18px;
        }
    }

    @media (min-width:1200px) {

        .resources .item {
            padding: 30px;
        }

        .resources .item .heading {
            font-size: 20px;
        }
    }
    /*  --------------------------------------------------
    9.7 TABLES
    -------------------------------------------------- */
    table {
        width: 100%;
        border-width: 1px 0 0 1px;
        border-style: solid;
        border-color: $c6;
        @include border-color($c6,1);
        background-color: $c4;
        @include background-color($c4,1);
    }

    thead {
        color: $c3;
        @include color($c3,1);
        background-color: $c6;
        @include background-color($c6,1);
    }

    table th, table td {
        padding: 15px;
        border-width: 0 1px 1px 0;
        border-style: solid;
        border-color: $c6;
        @include border-color($c6,1);
    }

    table tr:nth-of-type(even) td {
        background-color: $c5;
        @include background-color($c5,1);
    }

    .tables table tr:nth-of-type(even) td, .tables .body .item:nth-of-type(even) {
        background-color: $c5;
        @include background-color($c5,1);
    }

    table th {
        font-weight: 500;
        font-size: 15px;
    }

    table tr p:first-of-type {
        margin-top: 0;
    }

    table tr p:last-of-type {
        margin-bottom: 0;
    }

    @media (min-width:768px) {
    }

    @media (min-width:992px) {
    }

    @media (min-width:1200px) {
    }
    /*  --------------------------------------------------
    9.8 COMMENTS 
    -------------------------------------------------- */
    #comments {
        padding-top: 30px;
    }
    /*  --------------------------------------------------
    9.9 AUTHOR BIO
    -------------------------------------------------- */
    
    body.standard-page .spc + .spc.author-bio {
        margin-top: -30px;
    }


    //Client doesn't like the author bio section. Disabling through css here. 
    .author-bio,
    .author-bio .inner {
        display: none;
        overflow: hidden;
        height: 100%;
    }

    .author-bio p.heading {
        margin-bottom: 0;
    }

    .author-bio .image {
        margin-top: 15px;
    }

    .author-bio .image img {
        width: auto;
        max-width: 100%;
    }

    .author-bio .info {
        padding: 15px 0;
    }

    .author-bio .info p.heading {
        font-size: 16px;
    }

    .author-bio .info .text p {
    }

    @media (min-width:768px) {

        .author-bio .info {
            padding: 15px 15px 15px 0;
        }
    }

    @media (min-width:992px) {

        .author-bio .image {
            float: right;
            margin: 0 0 15px 40px;
        }
    }

    @media (min-width:1200px) {
    }
    /*  --------------------------------------------------
	9.10 IMAGE SLIDESHOW
    -------------------------------------------------- */
    .slideshow {
    }

    .slideshow .item {
        display: block;
    }

    .slideshow .item .image {
    }

    .slideshow .item .image img {
        display: block;
        overflow: hidden;
    }

    .slideshow .item .info {
    }

    .slideshow .item .info .text {
        padding: 10px 0 0;
        font-size: 80%;
        -webkit-opacity: 0.6;
        -moz-opacity: 0.6;
        -ms-opacity: 0.6;
        opacity: 0.6;
    }
    /*  --------------------------------------------------
	9.11 PRICING MENU
    -------------------------------------------------- */
    .pricing-menu {
    }

    .pricing-menu .head {
        padding: 10px 0 20px;
        margin-bottom: 10px;
        border-width: 0 0 2px;
        border-style: solid;
        border-color: #c6131a;
        @include border-color($c1,1);
    }

    .pricing-menu .head .heading {
        margin: 0;
        font-size: 14px;
    }

    .pricing-menu .item {
        padding: 15px 0;
        border-width: 0 0 1px;
        border-style: solid;
        border-color: $c6;
        @include border-color($c6,1);
    }

    .pricing-menu .item:last-of-type {
        border: 0 none;
    }

    .pricing-menu .item .heading {
        text-transform: none;
        letter-spacing: 0;
        font-size: 16px;
        margin: 0 0 8px;
    }

    .pricing-menu .item .text p {
        margin: 0 0 5px;
        font-size: 90%;
        font-style: italic;
    }

    .pricing-menu .price p {
        margin: 5px 0 0;
    }

    @media (min-width:768px) {
    }

    @media (min-width:992px) {

        .pricing-menu .item .heading {
            font-size: 18px;
        }

        .pricing-menu .item .price {
            text-align: right;
        }

        .pricing-menu .price p {
            margin: 0 0 10px;
        }
    }

    @media (min-width:1200px) {
    }
    /*  --------------------------------------------------
	9.12 TEXT BLOCK
    -------------------------------------------------- */
    .spc.textblock {
        margin-bottom: 15px;
    }

    .spc.textblock :first-child {
        margin-top: 0;
    }

    .spc.textblock :last-child {
        margin-bottom: 0;
    }
    /* BODY COLOR */
    body .apc.c1-bg .spc.textblock {
        color: $c6;
        @include color($c6,1);
    }

    body .apc.c2-bg .spc.textblock {
        color: $c6;
        @include color($c6,1);
    }

    body .apc.c3-bg .spc.textblock {
        color: $c6;
        @include color($c6,1);
    }

    body .apc.c4-bg .spc.textblock {
    }

    body .apc.c5-bg .spc.textblock {
    }

    body .apc.c6-bg .spc.textblock {
    }
    /* LINKS */
    body .apc.c1-bg .spc.textblock a, body .apc.c1-bg .spc.textblock a:active, body .apc.c1-bg .spc.textblock a:focus {
        color: $c5;
        @include color($c5,1);
    }

    body .apc.c2-bg .spc.textblock a, body .apc.c2-bg .spc.textblock a:active, body .apc.c2-bg .spc.textblock a:focus {
        color: $c5;
        @include color($c5,1);
    }

    body .apc.c3-bg .spc.textblock a, body .apc.c3-bg .spc.textblock a:active, body .apc.c3-bg .spc.textblock a:focus {
        color: $c5;
        @include color($c5,1);
    }

    body .apc.c4-bg .spc.textblock a, body .apc.c4-bg .spc.textblock a:active, body .apc.c4-bg .spc.textblock a:focus {
    }

    body .apc.c5-bg .spc.textblock a, body .apc.c5-bg .spc.textblock a:active, body .apc.c5-bg .spc.textblock a:focus {
    }

    body .apc.c6-bg .spc.textblock a, body .apc.c6-bg .spc.textblock a:active, body .apc.c6-bg .spc.textblock a:focus {
    }
    /* HEADINGS */
    body .apc.c1-bg .spc.textblock h1, body .apc.c1-bg .spc.textblock h2, body .apc.c1-bg .spc.textblock h3,
    body .apc.c1-bg .spc.textblock h4, body .apc.c1-bg .spc.textblock h5, body .apc.c1-bg .spc.textblock h6,
    body .apc.c1-bg .spc.textblock .heading {
        color: $c5;
        @include color($c5,1);
    }

    body .apc.c2-bg .spc.textblock h1, body .apc.c2-bg .spc.textblock h2, body .apc.c2-bg .spc.textblock h3,
    body .apc.c2-bg .spc.textblock h4, body .apc.c2-bg .spc.textblock h5, body .apc.c2-bg .spc.textblock h6,
    body .apc.c2-bg .spc.textblock .heading {
        color: $c5;
        @include color($c5,1);
    }

    body .apc.c3-bg .spc.textblock h1, body .apc.c3-bg .spc.textblock h2, body .apc.c3-bg .spc.textblock h3,
    body .apc.c3-bg .spc.textblock h4, body .apc.c3-bg .spc.textblock h5, body .apc.c3-bg .spc.textblock h6,
    body .apc.c3-bg .spc.textblock .heading {
        color: $c5;
        @include color($c5,1);
    }

    body .apc.c4-bg .spc.textblock h1, body .apc.c4-bg .spc.textblock h2, body .apc.c4-bg .spc.textblock h3,
    body .apc.c4-bg .spc.textblock h4, body .apc.c4-bg .spc.textblock h5, body .apc.c4-bg .spc.textblock h6,
    body .apc.c4-bg .spc.textblock .heading {
    }

    body .apc.c5-bg .spc.textblock h1, body .apc.c5-bg .spc.textblock h2, body .apc.c5-bg .spc.textblock h3,
    body .apc.c5-bg .spc.textblock h4, body .apc.c5-bg .spc.textblock h5, body .apc.c5-bg .spc.textblock h6,
    body .apc.c5-bg .spc.textblock .heading {
    }

    body .apc.c6-bg .spc.textblock h1, body .apc.c6-bg .spc.textblock h2, body .apc.c6-bg .spc.textblock h3,
    body .apc.c6-bg .spc.textblock h4, body .apc.c6-bg .spc.textblock h5, body .apc.c6-bg .spc.textblock h6,
    body .apc.c6-bg .spc.textblock .heading {
    }
    /*  ----------------------------------------------------------------------------------------------------
    10.0 ADVANCED PAGE COMPONENTS
    ---------------------------------------------------------------------------------------------------- */
    .apc {
        position: relative;
        padding: 25px 0;
        overflow: hidden;
        height: 100%;
    }

    .apc .overview {
        margin-bottom: 25px;
    }

    .apc .outro {
        margin-top: 25px;
    }

    .apc .overview .text :first-child,
    .apc .outro .text :first-child {
        margin-top: 0;
    }

    .apc .overview .text :last-child,
    .apc .outro .text :last-child {
        margin-bottom: 0;
    }

    .apc.no-padding .overview {
        margin-top: 25px;
    }

    .apc.no-padding .outro {
        margin-bottom: 25px;
    }

    .apc.pod-windows .container-fluid:first-child {
        margin-top: -25px;
    }

    .apc.pod-windows .container-fluid:last-child {
        margin-bottom: -25px;
    }


    .apc.banner {
        padding: 0 !important;
    }

    .apc.banner,
    .apc.promo-pods {
        margin: 0;
    }

    .apc .heading.main {
        font-size: 25px;
        margin: 0;
    }

    .apc .overview .heading.main + .text {
        margin: 15px 0 0;
    }

    .apc .outro .text + .link {
        margin: 20px 0 0;
    }

    .apc .outro .link .btn {
        margin: 5px 5px 0 0;
    }

    @media (min-width:768px) {

        .apc {
            padding: 30px 0;
        }

        .apc.no-padding .overview {
            margin-top: 30px;
        }

        .apc.no-padding .outro {
            margin-bottom: 30px;
        }

        .apc.pod-windows .container-fluid:first-child {
            margin-top: -30px;
        }

        .apc.pod-windows .container-fluid:last-child {
            margin-bottom: -30px;
        }

        .apc .heading.main {
            font-size: 30px;
        }
    }

    @media (min-width:992px) {

        .apc {
            padding: 45px 0;
        }

        .apc.no-padding .overview {
            margin-top: 45px;
        }

        .apc.no-padding .outro {
            margin-bottom: 45px;
        }

        .apc.pod-windows .container-fluid:first-child {
            margin-top: -45px;
        }

        .apc.pod-windows .container-fluid:last-child {
            margin-bottom: -45px;
        }

        .apc .heading.main {
            font-size: 35px;
        }
    }

    @media (min-width:1200px) {

        .apc {
            padding: 60px 0;
        }

        .apc.no-padding .overview {
            margin-top: 60px;
        }

        .apc.no-padding .outro {
            margin-bottom: 60px;
        }

        .apc.pod-windows .container-fluid:first-child {
            margin-top: -60px;
        }

        .apc.pod-windows .container-fluid:last-child {
            margin-bottom: -60px;
        }

        .apc .heading.main {
            font-size: 40px;
        }
    }
    /*  --------------------------------------------------
    10.1 STANDARD PAGE COMPONENTS ON ADVANCED PAGE
    -------------------------------------------------- */
    .apc.standard-components {
    }

    .apc.standard-components .spc,
    .apc.standard-components #wide-col,
    .apc.standard-components #mid-col {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    @media (min-width:768px) {
    }

    @media (min-width:992px) {
    }

    @media (min-width:1200px) {
    }
    /*  --------------------------------------------------
    10.3 CTA STRIP  
    -------------------------------------------------- */
    .apc.cta-strip {
    }

    .apc.cta-strip .heading.main {
        margin-bottom: 0;
    }

    .apc.cta-strip p.link {
        margin-top: 0;
    }

    @media (min-width:768px) {
    }

    @media (min-width:992px) {
    }

    @media (min-width:1200px) {
    }
    /*  --------------------------------------------------
    10.4 APC PROMO PODS  
    -------------------------------------------------- */
    .apc.promo-pods {
    }

    @media (max-width:991px) {

        .apc.promo-pods .item:nth-of-type(2n+1) {
            clear: both !important;
        }
    }

    @media (min-width:992px) {
    }
    /*  --------------------------------------------------
    10.6 TEXT WITH FORM  
    -------------------------------------------------- */
    .apc.text-with-form {
    }

    @media (min-width:768px) {
    }

    @media (min-width:992px) {

        .apc.text-with-form .info .heading.main {
            margin-top: 20px;
        }
    }

    @media (min-width:1200px) {

        .apc.text-with-form .info .heading.main {
            margin-top: 30px;
        }
    }
    /*  --------------------------------------------------
    10.7 SIGNUP WITH LISTING
    -------------------------------------------------- */
    .apc.signup-listing {
    }

    .apc.signup-listing .listing .item {
        margin: 20px 0 0;
        position: relative;
    }

    .apc.signup-listing .listing .item:first-of-type {
        margin-top: 40px;
    }

    .apc.signup-listing .listing .item .heading {
        font-size: 16px;
    }

    .apc.signup-listing .listing .item .meta {
    }

    .signup-listing.c1-bg .listing .text, .signup-listing.c1-bg .listing .meta,
    .signup-listing.c2-bg .listing .text, .signup-listing.c2-bg .listing .meta,
    .signup-listing.c3-bg .listing .text, .signup-listing.c3-bg .listing .meta {
        color: #e1e2e5;
        color: rgba(225,226,229,1);
    }

    .signup-listing.c1-bg .listing .heading, .signup-listing.c2-bg .listing .heading, .signup-listing.c3-bg .listing .heading,
    .signup-listing.c1-bg .listing .meta a, .signup-listing.c2-bg .listing .meta a, .signup-listing.c3-bg .listing .meta a {
        color: $c5;
        @include color($c5,1);
    }

    @media (min-width:768px) {

        .apc.signup-listing .listing .item .heading {
            font-size: 18px;
        }
    }

    @media (min-width:992px) {

        .apc.signup-listing .listing .heading.main {
            margin-top: 20px;
        }

        .apc.signup-listing .listing .item .heading {
            font-size: 20px;
        }
    }

    @media (min-width:1200px) {

        .apc.signup-listing .listing .heading.main {
            margin-top: 30px;
        }

        .apc.signup-listing .listing .item .heading {
            font-size: 22px;
        }
    }
    /*  --------------------------------------------------
    10.8 APC TABBED
    -------------------------------------------------- */
    .apc.tabbed {
    }

    .apc.tabbed .tab-content {
        border: 0 none;
        padding: 0;
        background: none;
    }

    .apc.tabbed .listing {
        margin: 0;
    }
    /*  --------------------------------------------------
    10.9 GRID
    -------------------------------------------------- */
    .apc.umb-grid {
    }

    .apc.umb-grid .grid-section > div + div {
        margin-top: 15px;
    }
    /* BODY COLOR */
    body .apc.umb-grid.c1-bg {
        color: $c6;
        @include color($c6,1);
    }

    body .apc.umb-grid.c2-bg {
        color: $c6;
        @include color($c6,1);
    }

    body .apc.umb-grid.c3-bg {
        color: $c6;
        @include color($c6,1);
    }

    body .apc.umb-grid.c4-bg {
    }

    body .apc.umb-grid.c5-bg {
    }

    body .apc.umb-grid.c6-bg {
    }
    /* LINKS */
    body .apc.umb-grid.c1-bg a, body .apc.umb-grid.c1-bg a:active, body .apc.umb-grid.c1-bg a:focus {
        color: $c5;
        @include color($c5,1);
    }

    body .apc.umb-grid.c2-bg a, body .apc.umb-grid.c2-bg a:active, body .apc.umb-grid.c2-bg a:focus {
        color: $c5;
        @include color($c5,1);
    }

    body .apc.umb-grid.c3-bg a, body .apc.umb-grid.c3-bg a:active, body .apc.umb-grid.c3-bg a:focus {
        color: $c5;
        @include color($c5,1);
    }

    body .apc.umb-grid.c4-bg a, body .apc.umb-grid.c4-bg a:active, body .apc.umb-grid.c4-bg a:focus {
    }

    body .apc.umb-grid.c5-bg a, body .apc.umb-grid.c5-bg a:active, body .apc.umb-grid.c5-bg a:focus {
    }

    body .apc.umb-grid.c6-bg a, body .apc.umb-grid.c6-bg a:active, body .apc.umb-grid.c6-bg a:focus {
    }
    /* HEADINGS */
    body .apc.umb-grid.c1-bg h1, body .apc.umb-grid.c1-bg h2, body .apc.umb-grid.c1-bg h3,
    body .apc.umb-grid.c1-bg h4, body .apc.umb-grid.c1-bg h5, body .apc.umb-grid.c1-bg h6,
    body .apc.umb-grid.c1-bg .heading {
        color: $c5;
        @include color($c5,1);
    }

    body .apc.umb-grid.c2-bg h1, body .apc.umb-grid.c2-bg h2, body .apc.umb-grid.c2-bg h3,
    body .apc.umb-grid.c2-bg h4, body .apc.umb-grid.c2-bg h5, body .apc.umb-grid.c2-bg h6,
    body .apc.umb-grid.c2-bg .heading {
        color: $c5;
        @include color($c5,1);
    }

    body .apc.umb-grid.c3-bg h1, body .apc.umb-grid.c3-bg h2, body .apc.umb-grid.c3-bg h3,
    body .apc.umb-grid.c3-bg h4, body .apc.umb-grid.c3-bg h5, body .apc.umb-grid.c3-bg h6,
    body .apc.umb-grid.c3-bg .heading {
        color: $c5;
        @include color($c5,1);
    }

    body .apc.umb-grid.c4-bg h1, body .apc.umb-grid.c4-bg h2, body .apc.umb-grid.c4-bg h3,
    body .apc.umb-grid.c4-bg h4, body .apc.umb-grid.c4-bg h5, body .apc.umb-grid.c4-bg h6,
    body .apc.umb-grid.c4-bg .heading {
    }

    body .apc.umb-grid.c5-bg h1, body .apc.umb-grid.c5-bg h2, body .apc.umb-grid.c5-bg h3,
    body .apc.umb-grid.c5-bg h4, body .apc.umb-grid.c5-bg h5, body .apc.umb-grid.c5-bg h6,
    body .apc.umb-grid.c5-bg .heading {
    }

    body .apc.umb-grid.c6-bg h1, body .apc.umb-grid.c6-bg h2, body .apc.umb-grid.c6-bg h3,
    body .apc.umb-grid.c6-bg h4, body .apc.umb-grid.c6-bg h5, body .apc.umb-grid.c6-bg h6,
    body .apc.umb-grid.c6-bg .heading {
    }
    /*  --------------------------------------------------
    10.10 IMAGE CAROUSEL 
    -------------------------------------------------- */
    .apc.image-carousel {
    }

    .apc.image-carousel .slides {
        padding-bottom: 60px;
    }

    .apc.image-carousel .slick-prev,
    .apc.image-carousel .slick-next {
        top: auto;
        right: 0;
        bottom: 0;
        left: auto;
        transform: none;
    }

    .apc.image-carousel .slick-prev {
        right: 50px;
    }

    @media (min-width:768px) {
    }

    @media (min-width:992px) {

        .apc.image-carousel .slick-prev,
        .apc.image-carousel .slick-next {
            bottom: -15px;
        }
    }

    @media (min-width:1200px) {
    }
    /*  --------------------------------------------------
    10.11 FEATURED BLOGS
    -------------------------------------------------- */
    .apc.featured-blogs {
    }

    .apc.featured-blogs .slides {
        padding-bottom: 60px;
    }

    .apc.featured-blogs .slick-prev,
    .apc.featured-blogs .slick-next {
        top: auto;
        right: 15px;
        bottom: 0;
        left: auto;
        transform: none;
    }

    .apc.featured-blogs .slick-prev {
        right: 65px;
    }

    .apc.featured-blogs .listing {
        overflow: visible;
        padding: 0 15px;
        margin: 0 -15px;
    }

    .apc.featured-blogs .listing .item {
        margin: 0 15px;
        clear: none !important;
    }


    @media (min-width:768px) {
    }

    @media (min-width:992px) {

        .apc.featured-blogs .slick-prev,
        .apc.featured-blogs .slick-next {
            bottom: -15px;
        }
    }

    @media (min-width:1200px) {
    }
    /*  --------------------------------------------------
    10.12 POD WINDOWS
    -------------------------------------------------- */
    .apc.pod-windows {
    }

    .apc.pod-windows .container-fluid {
        padding: 0;
    }

    .apc.pod-windows .row:not(.overview):not(.outro) {
        margin: 0;
    }

    .apc.pod-windows .row.smaller-items {
        padding: 0;
    }

    .apc.pod-windows .item {
        position: relative;
        overflow: hidden;
        padding: 0;
    }

    .apc.pod-windows .item::before {
        content: "";
        display: block;
        padding-top: 100%;
    }

    .apc.pod-windows .item.half-item::before {
        padding-top: 50%;
    }

    .apc.pod-windows .item .info {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        display: block;
        width: 100%;
        height: 100%;
        color: #555555;
        text-decoration: none;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
    }

    .apc.pod-windows .item .info::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
    }

    .apc.pod-windows .item:not(.video) a.info .inner {
        opacity: 0;
        -webkit-transform: scale(1.25);
        -moz-transform: scale(1.25);
        -ms-transform: scale(1.25);
        -o-transform: scale(1.25);
        transform: scale(1.25);
    }

    html.no-touch .apc.pod-windows .item:not(.video) a.info:hover .inner, html.no-touch .apc.pod-windows .item:not(.video) a.info:focus .inner {
        opacity: 1;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }



    .apc.pod-windows .item .heading {
        margin: 0;
    }

    .apc.pod-windows .item .heading + .text {
        margin-top: 10px;
    }

    .apc.pod-windows .item .text :first-child {
        margin-top: 0;
    }

    .apc.pod-windows .item .text :last-child {
        margin-bottom: 0;
    }

    .apc.pod-windows .item .link {
        margin-top: 20px;
        margin-bottom: 0;
    }

    .apc.pod-windows .item.video .link {
        position: absolute;
        right: 0;
        bottom: 20px;
        left: 0;
        z-index: 120;
        width: 100%;
        -webkit-transition: bottom 0.2s ease-in-out 0s, opacity 0.15s ease-in-out 0s;
        -moz-transition: bottom 0.2s ease-in-out 0s, opacity 0.15s ease-in-out 0s;
        -ms-transition: bottom 0.2s ease-in-out 0s, opacity 0.15s ease-in-out 0s;
        transition: bottom 0.2s ease-in-out 0s, opacity 0.15s ease-in-out 0s;
    }

    .apc.pod-windows .item .link span {
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 400;
        display: inline-block;
        border-width: 0 0 1px;
        border-style: solid;
        border-color: inherit;
    }

    html.no-touch .apc.pod-windows .item.video a.info .link {
        bottom: -40px;
        opacity: 0;
    }



    html.no-touch .apc.pod-windows .item.video a:hover .link, html.no-touch .apc.pod-windows .item.video a:focus .link {
        bottom: 20px;
        opacity: 1;
    }

    .apc.pod-windows .item .info .overlayicon {
        background: none;
        font-size: 100px;
    }


    @media (min-width:0) and (max-width:499px) {
    }

    @media (min-width:500px) {

        .apc.pod-windows .item.quarter-item {
            width: 50%;
        }
    }

    @media (min-width:992px) {

        .apc.pod-windows .full-item .heading {
            font-size: 30px;
        }

        .apc.pod-windows .half-item .heading {
            font-size: 25px;
        }

        .apc.pod-windows .quarter-item .heading {
            font-size: 20px;
        }
    }

    @media (min-width:1200px) {

        .apc.pod-windows .full-item .heading {
            font-size: 50px;
        }

        .apc.pod-windows .half-item .heading {
            font-size: 40px;
        }

        .apc.pod-windows .quarter-item .heading {
            font-size: 30px;
        }

        .apc.pod-windows .item .heading,
        .apc.pod-windows .item .text {
            max-width: 400px;
            margin-right: auto;
            margin-left: auto;
        }

        .apc.pod-windows .item.video div.info .link,
        html.no-touch .apc.pod-windows .item.video a.info:hover .link, html.no-touch .apc.pod-windows .item.video a.info:focus .link {
            bottom: 30px;
        }


        .apc.pod-windows .item.full-item .overlayicon {
            font-size: 180px;
        }
    }
    /*  ----------------------------------------------------------------------------------------------------
    11.0 FORMS
    ---------------------------------------------------------------------------------------------------- */
    textarea {
        resize: none;
    }

    fieldset {
        margin: 0 0 16px;
    }

    legend {
        padding: 0 0 8px;
        margin: 8px 0 16px;
    }

    label {
        font-weight: normal;
        display: block;
    }

    em.req {
        font-style: normal;
    }

    .form-control {
        border-radius: 0;
        min-height: 40px;
    }

    .form-control:focus {
        border-color: #c6131a;
        @include border-color($c1,1);
        box-shadow: 0 0 5px rgba(198,19,26,1);
    }

    select, select option {
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        appearance: none;
    }

    fieldset label em.req {
        color: #a94442;
        color: rgba(169,68,68,1);
    }
    /* VALIDATION */
    .input-validation-error {
        border-color: #a94442;
    }

    .input-validation-error:focus {
        border-color: #a94442;
        box-shadow: 0 1px 1px rgba(0,0,0,0.075) inset, 0 0 8px rgba(169,68,66,0.5);
    }

    .field-validation-error {
        display: block;
        padding: 5px;
        font-size: 12px;
        background-color: #a94442;
        color: $c5;
        @include color($c5,1);
    }

    .field-validation-valid {
        display: none;
    }

    .validation-summary-valid {
        display: none;
    }

    footer .newsletter fieldset {
        position: relative;
    }

    footer .newsletter .btn {
        position: absolute;
        top: 0;
        right: 0;
        min-height: 40px;
    }

    input[type="submit"] {
        color: #1176BB;
        background-color: transparent;
        border-style: solid;
        border-width: 1px;
        border-color: #c6131a;
    }

    .checkboxlist input {
        width: unset;
    }

    .checkboxlist .checkboxgroup {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .checkboxlist .checkboxgroup label {
        padding-left: 10px;
        padding-top: 8px;
    }
    /*	----------------------------------------------------------------------------------------------------
	11.1 SITE SEARCH
	---------------------------------------------------------------------------------------------------- */
    .site-search {
        position: relative;
        margin: 15px 0;
        font-weight: 600;

        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $c2;
            font-weight: 600;
        }

        ::-moz-placeholder { /* Firefox 19+ */
            color: $c2;
            font-weight: 600;
        }

        :-ms-input-placeholder { /* IE 10+ */
            color: $c2;
            font-weight: 600;
        }

        :-moz-placeholder { /* Firefox 18- */
            color: $c2;
            font-weight: 600;
        }
    }

    header .site-search {
        padding: 0 15px;
    }

    .site-search fieldset {
        position: relative;
        pointer-events: all;
        margin: 0;
        padding: 0;
    }

    .site-search .form-group {
        margin: 0;
        padding: 0;
    }

    .site-search .form-control {
        width: 100%;
        height: 40px;
        min-height: 50px;
        padding-right: 50px;
        font-weight: 600;
    }

    .site-search button {
        width: 50px;
        height: 50px;
        padding: 0;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        text-align: center;
        border: 0 none;

        &:hover, &:focus {
            span:before {
                color: #fff;
            }
        }
    }

    @media (min-width:768px) {

        header .site-search {
            padding: 0;
            margin: 15px auto;
            max-width: 460px;
        }
    }

    @media (min-width:992px) {

        header .site-search {
            margin: 30px auto;
        }
    }

    @media (min-width:1200px) {

        header .site-search {
            margin: 40px auto;
        }

        header .site-search .form-control {
            font-size: 18px;
            height: 60px;
            min-height: 60px;
            padding-right: 60px;
        }

        header .site-search button {
            width: 60px;
            height: 60px;
        }
    }

    @media (min-width:992px) {

        header .site-search {
            margin: 30px auto;
        }
    }

    @media (min-width:1200px) {

        header .site-search {
            margin: 40px auto;
        }

        header .site-search .form-control {
            font-size: 18px;
            height: 60px;
            min-height: 60px;
            padding-right: 60px;
        }

        header .site-search button {
            width: 60px;
            height: 60px;
        }
    }
    /*  --------------------------------------------------
    11.2 AJAX LOADING         
    -------------------------------------------------- */
    .ajax-wrap {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .ajax-wrap .ajax-loading {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        @include background-color($c5,0.9);
    }

    .ajax-wrap .ajax-loading .loading {
        margin: 0;
        position: relative;
        text-align: center;
        display: table;
        width: 100%;
        height: 100%;
    }

    .ajax-wrap .ajax-loading .loading span.message {
        display: table-cell;
        width: 100%;
        height: 100%;
        text-indent: -9999px;
        vertical-align: middle;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('/images/icons/loading-on-white.gif');
    }

    @media (min-width:768px) {
    }

    @media (min-width:992px) {
    }

    @media (min-width:1200px) {
    }
    /*  --------------------------------------------------
    11.3 COOKIES
    -------------------------------------------------- */
    .cookie-notice {
        position: fixed;
        right: 0;
        bottom: -100%;
        left: 0;
        z-index: 1000;
        padding: 15px 0;
        opacity: 0;
        -webkit-transition: bottom 1s ease-in-out 0.2s, opacity 0.75s ease-in-out 0.1s, box-shadow 1s ease-in-out 0.2s;
        -moz-transition: bottom 1s ease-in-out 0.2s, opacity 0.75s ease-in-out 0.1s, box-shadow 1s ease-in-out 0.2s;
        -ms-transition: bottom 1s ease-in-out 0.2s, opacity 0.75s ease-in-out 0.1s, box-shadow 1s ease-in-out 0.2s;
        -o-transition: bottom 1s ease-in-out 0.2s, opacity 0.75s ease-in-out 0.1s, box-shadow 1s ease-in-out 0.2s;
        transition: bottom 1s ease-in-out 0.2s, opacity 0.75s ease-in-out 0.1s, box-shadow 1s ease-in-out 0.2s;
    }

    .cookie-notice.open {
        bottom: 0;
        opacity: 1;
    }

    .cookie-notice .heading {
        margin: 0;
    }

    .cookie-notice .heading + .text {
        margin-top: 10px;
    }

    .cookie-notice .text :first-child {
        margin-top: 0;
    }

    .cookie-notice .text :last-child {
        margin-bottom: 0;
    }

    .cookie-notice p.link {
        margin: 15px 0 0;
    }

    @media (min-width:768px) {

        .cookie-notice p.link {
            margin: 0;
        }

        .cookie-notice p.link a.btn {
            display: block;
        }
    }

    @media (min-width:992px) {

        .cookie-notice {
            padding: 20px 0;
        }
    }

    @media (min-width:1200px) {

        .cookie-notice {
            padding: 30px 0;
        }
    }
    /*	--------------------------------------------------
	12.0 MISC
	-------------------------------------------------- */
    .inner {
        padding: 15px;
    }

    .spc.quick-links .inner {
        padding-bottom: 5px;
    }

    .has-borders {
        border-width: 1px;
        border-style: solid;
    }

    .has-border-top {
        border-width: 1px 0 0;
        border-style: solid;
    }

    .has-border-bottom {
        border-width: 0 0 1px;
        border-style: solid;
    }

    .rounded {
        border-radius: 3px;
    }

    .item .table {
        margin: 0;
    }

    .table-cell {
        padding: 30px;
    }

    .item-square {
        padding-bottom: 100%;
    }

    .item-square .info {
        max-width: 640px;
        margin: 0 auto;
        padding: 0;
    }

    .item-square .inner {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    div.table {
        display: table;
        width: 100%;
        height: 100%;
        margin: 0;
    }

    div.table-cell {
        display: table-cell;
        vertical-align: middle;
        padding: 0;
    }

    div.table-cell.align-top {
        vertical-align: top;
        padding-top: 100px;
    }

    div.table-cell.align-bottom {
        vertical-align: bottom;
        padding-bottom: 100px;
    }

    .no-padding {
        padding: 0 !important;
    }

    .no-padding-top {
        padding-top: 0 !important;
    }

    .no-padding-bottom {
        padding-bottom: 0 !important;
    }

    .padding-top {
        padding-top: 30px !important;
    }

    .padding-bottom {
        padding-bottom: 30px !important;
    }

    .short-padding {
        padding: 20px 0 !important;
    }

    .short-padding-top {
        padding-top: 20px !important;
    }

    .short-padding-bottom {
        padding-bottom: 20px !important;
    }

    .no-margin {
        margin: 0 !important;
    }

    .no-margin-top {
        margin-top: 0 !important;
    }

    .no-margin-bottom {
        margin-bottom: 0 !important;
    }

    .margin-top {
        margin-top: 30px !important;
    }

    .margin-bottom {
        margin-bottom: 30px !important;
    }

    .short-margin {
        margin: 20px 0 !important;
    }

    .short-margin-top {
        margin-top: 20px !important;
    }

    .short-margin-bottom {
        margin-bottom: 20px !important;
    }


    @media (max-width:991px) {

        .item-square {
            padding-bottom: 75%;
        }
    }

    @media (min-width:768px) {

        .item-square {
            padding-bottom: 50%;
        }

        .item-square .info {
            padding: 0;
        }
    }

    @media (min-width:992px) {

        .container-fluid .item-square {
        }

        .inner {
            padding: 20px;
        }

        .spc.quick-links .inner {
            padding-bottom: 10px;
        }
    }

    @media (min-width:1200px) {

        .container-fluid .item-square {
            padding-bottom: 35%;
        }

        .inner {
            padding: 30px;
        }

        .spc.quick-links .inner {
            padding-bottom: 20px;
        }
    }
    /*	--------------------------------------------------
	12.1 OVERLAY ICONS
	-------------------------------------------------- */
    .image {
    }

    .image a {
        display: block;
        position: relative;
        overflow: hidden;
    }

    .overlayicon {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 20;
        padding: 0;
        margin: 0;
        @include background-color($c3,0);
        text-align: center;
        font-size: 40px;
        display: table-cell;
        width: 100%;
        height: 100%;
        vertical-align: middle;
        -webkit-transition: background-color 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
        -moz-transition: background-color 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
        -ms-transition: background-color 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
        transition: background-color 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s;
        -webkit-opacity: 0;
        -moz-opacity: 0;
        -ms-opacity: 0;
        opacity: 0;
    }

    .item.video-pod a .overlayicon,
    div.video a .overlayicon {
        @include background-color($c3,0.25);
    }

    html.no-touch a:hover .overlayicon, html.no-touch a:focus .overlayicon,
    .item.video-pod a .overlayicon,
    div.video a .overlayicon {
        -webkit-opacity: 1;
        -moz-opacity: 1;
        -ms-opacity: 1;
        opacity: 1;
    }

    html.no-touch a:hover .overlayicon, html.no-touch a:focus .overlayicon,
    html.no-touch .item.video-pod a:hover .overlayicon, html.no-touch .item.video-pod a:focus .overlayicon,
    html.no-touch div.video a:hover .overlayicon, html.no-touch div.video a:focus .overlayicon {
        @include background-color($c3,0.75);
    }

    .overlayicon::before {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
    }

    .masonry .overlayicon::before {
        position: relative;
        top: auto;
        left: auto;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        transform: none;
        top: 35%;
    }


    @media (min-width:768px) {
    }

    @media (min-width:992px) {

        .overlayicon {
            font-size: 50px;
        }
    }

    @media (min-width:1200px) {

        .overlayicon {
            font-size: 60px;
        }
    }
    /*	--------------------------------------------------
	Custom
	-------------------------------------------------- */
    .flex {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-line-pack: justify;
        align-content: space-between;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    /*	--------------------------------------------------
	END
	-------------------------------------------------- */
