﻿@import "variables";

.apc .image {

    &.video {
        position: relative;
    }
}

.apc.text-with-image-or-video.wide .image {
    @media (min-width: 992px) {
        height: 500px !important;
    }
}
/*  --------------------------------------------------
    10.5 TEXT WITH IMAGE / VIDEO
    -------------------------------------------------- */
.apc.text-with-image-or-video {
    &.c1-bg, &.c2-bg, &.c3-bg {
        color: #fff;
    }
}

.apc.text-with-image-or-video .info {
    padding-top: 20px;
}

.apc.text-with-image-or-video .info .heading {
    margin-top: 0;
}

.apc.text-with-image-or-video p.link {
    margin-top: 30px;
    margin-bottom: 0;
}

.apc.text-with-image-or-video .overlayicon {
    font-size: 60px;
}

@media (min-width:768px) and (max-width:991px) {

    .apc.text-with-image-or-video.wide .image {
        max-width: 720px;
        margin: 0 auto;
    }
}

@media (min-width:992px) {

    .apc.text-with-image-or-video.wide {
        padding: 0;
    }

    .apc.text-with-image-or-video .item {
        display: table;
    }

    .apc.text-with-image-or-video .info {
        padding-top: 0;
        display: table-cell;
        vertical-align: middle;
    }

    .apc.text-with-image-or-video.wide .info {
        padding: 48px 0;
    }

    .apc.text-with-image-or-video .overlayicon {
        font-size: 100px;
    }

    .apc.text-with-image-or-video:not(.wide) .pull-right .image {
        margin-left: -15px;
    }

    .apc.text-with-image-or-video:not(.wide) .pull-left .image {
        margin-right: -15px;
    }

    .apc.text-with-image-or-video.wide .image {
        margin: 0 -15px;
    }
}

@media (min-width:1200px) {

    .apc.text-with-image-or-video.wide .info {
        padding: 10px 0;
    }

    .apc.text-with-image-or-video .overlayicon {
        font-size: 140px;
    }
}
